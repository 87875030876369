// import { useState } from "react";
import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import NewUser layout schemas for form and form fields
// Assuming form and validations are already defined in schemas (fix imports accordingly)
import validations from "./validations";
import form from "./form";
import initialValues from "./initialValues";

import FormField from "./FormField";
import { Autocomplete } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { groupCategoryOption, labourOption, methodOption, stockMethodOption, unitOption } from "../../../../demo_data/const";
import boxShadow from "assets/theme/functions/boxShadow";

const axios = require('axios').default

const AddItemForm = ({ showAsPopup }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  var randomString = function (len, bits) {
    bits = bits || 36
    var outStr = '',
      newStr
    while (outStr.length < len) {
      newStr = Math.random().toString(bits).slice(2)
      outStr += newStr.slice(0, Math.min(newStr.length, len - outStr.length))
    }
    return outStr.toUpperCase()
  }

    
  const submitForm = async (values, actions) => {
    await sleep(1000);
    console.log(values);

    var itemID = randomString(10)

    axios
      .post('https://admin.goldshopper.in/api/additem', {
        id: itemID,
				name: values.name,
				group_category: values.group_category,
				prefix: values.prefix,
				unit: values.unit,
				labour_upon: values.labour_upon,
				stock_method: values.stock_method,
				individual_price: values.individual_price,
				gold_fine_method: values.gold_fine_method,
				gold_fine: values.gold_fine,
				silver_fine_method: values.silver_fine_method,
				silver_fine: values.silver_fine,
				amount_method: values.amount_method,
				amount: values.amount,
      })
      .then( function (response) {
       console.log(response.data)
       if(response.data === 'done'){
        alert('Item Added')
        actions.setSubmitting(false);
        actions.resetForm();
       }

       //window.location.href = '/dashboards/analytics'
      })
      .catch(function (error) {
        alert('Something went wrong')
        //openErrorSB()
        console.log(error)
      })

    //alert(JSON.stringify(values, null, 2));

    
    //setActiveStep(0);
    // showAsPopup(false) // closing this page if it call as a popup
  };

  const handleSubmit = (values, actions) => {
    console.log('assadadasd');
    console.log(values);

    submitForm(values, actions);
  };


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={currentValidation}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form id={formId}>
          <Card autoComplete="off" sx={{ height: "100%", ...showAsPopup && { boxShadow: 'none' } }}>

            <MDBox p={3} pb={0}>
              <MDTypography variant="h5">Add a new Item</MDTypography>
            </MDBox>

            <MDBox p={3}>
              <MDBox component="form" pb={3}>
                <Grid container spacing={3}>




                  <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                    <FormField
                      name={formField.name.name}
                      label={formField.name.label}
                      autoFocus={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                    <Autocomplete
                      options={groupCategoryOption}
                      renderInput={(params) => <MDInput {...params} label={formField.group_category.label} variant="standard" />}
                    />
                  </Grid>

                  <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                    <FormField
                      name={formField.prefix.name}
                      label={formField.prefix.label}
                    />
                  </Grid>

                  <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                    <Autocomplete
                      options={unitOption}
                      renderInput={(params) => <MDInput {...params} label={formField.unit.label} variant="standard" />}
                    />
                  </Grid>

                  <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                    <Autocomplete
                      options={labourOption}
                      renderInput={(params) => <MDInput {...params} label={formField.labour_upon.label} variant="standard" />}
                    />
                  </Grid>

                  <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                    <Autocomplete
                      options={stockMethodOption}
                      renderInput={(params) => <MDInput {...params} label={formField.stock_method.label} variant="standard" />}
                    />
                  </Grid>

                  <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                    <FormField
                      name={formField.individual_price.name}
                      label={formField.individual_price.label}
                    />
                  </Grid>





                  <Grid item xs={12} sm={12} mt={5}>
                    <MDTypography variant="h5">Opening Balance Amount</MDTypography>
                  </Grid>




                  <Grid container item spacing={3}>
                    <Grid display="flex" gap={2} item xs={12} sm={showAsPopup ? 12 : 3}>
                      <Autocomplete
                        style={{ width: '100px' }}
                        options={methodOption}
                        renderInput={(params) => <MDInput {...params} label={formField.gold_fine_method.label} variant="standard" />}
                      />
                      <FormField
                        name={formField.gold_fine.name}
                        label={formField.gold_fine.label}
                      />
                    </Grid>


                    <Grid display="flex" gap={2} item xs={12} sm={showAsPopup ? 12 : 3}>
                      <Autocomplete
                        style={{ width: '100px' }}
                        options={methodOption}
                        renderInput={(params) => <MDInput {...params} label={formField.silver_fine_method.label} variant="standard" />}
                      />
                      <FormField
                        name={formField.silver_fine.name}
                        label={formField.silver_fine.label}
                      />
                    </Grid>


                    <Grid display="flex" gap={2} item xs={12} sm={showAsPopup ? 12 : 3}>
                      <Autocomplete
                        style={{ width: '100px' }}
                        options={methodOption}
                        renderInput={(params) => <MDInput {...params} label={formField.amount_method.label} variant="standard" />}
                      />
                      <FormField
                        name={formField.amount.name}
                        label={formField.amount.label}
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </MDBox>
              <MDBox mt={2} gap={2} width="100%" display="flex" justifyContent="flex-start">
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="primary"
                  disabled={isSubmitting}
                  // onClick={() => {
                  //   showAsPopup && showAsPopup(false) // closing this page if it call as a popup
                  // }}
                > Create New Item </MDButton>
                {/* <MDButton type="reset" variant="gradient" color="dark">Reset</MDButton> */}
              </MDBox>
            </MDBox>
          </Card>
        </Form>
      )
      }
    </Formik >
  );
}

export default AddItemForm;