import React, { useState } from 'react'
import { TableContainer } from '@mui/material'
import { DeletButtonForTable, TableInnerStyle } from './style'
import { SetFormattedIndianNumber } from 'components/globalFunctions'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ConditionalCompare, defaultSimilerRow, GetCalculationOf_Row2_Fine, highlightSimilerRow } from './functions'
import PanelPopup from './PanelPopup'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { transactionsTypeOption2 } from 'demo_data/const'
import { transactionsTypeOption3 } from 'demo_data/const'
import { SetNumbersDecimals } from 'components/globalFunctions';



const SalePanelRow = ({ panels, setPanels, saleItems, deleteRow2 }) => {





  // function updateRow2(param, val, index) {
  //   console.log(panels)
  //   var rowsNew = panels

  //   console.log(param, val, index)
  //   if (param === 'Panel') {
  //     rowsNew[index].panel = val
  //   } else if (param === 'AmtGrwt') {
  //     rowsNew[index].amtgrwt = val
  //   } else if (param === 'Tunch') {
  //     rowsNew[index].tunch = val
  //   } else if (param === 'Rate') {
  //     rowsNew[index].rate = val
  //   } else if (param === 'Fine') {
  //     rowsNew[index].fine = val
  //   } else if (param === 'Total') {
  //     rowsNew[index].total = val
  //   } else {
  //     console.log('NA')
  //   }

  //   setPanels(rowsNew)

  // }


  const [panelPopupState, setPanelPopupState] = useState({ visibility: false, type: 'add' });
  const [panelPopupData, setPanelPopupData] = useState({ type: 'inflow', method: 'cash' });






  // Managing Add Item Popup
  const handleToManageAddItemPopup = (rowData, add_edit) => {

    if (!rowData || rowData <= 0) { return }

    setPanelPopupData({
      type: rowData?.type,
      method: rowData?.method,
      miaGoldBhav: rowData?.miaGoldBhav,
      bank: null,
      useAsGoldBhav: rowData?.useAsGoldBhav,
      id: rowData?.id,
      grwt: rowData?.grwt ? rowData?.grwt : '0.000',
      // grwt: rowData?.grwt ?? (
      //   ConditionalCompare(rowData.method, '===', 'gold bhav')
      //     ? (TotalCalculationOf_Fine(saleItems) - TotalCalculationOf_Row2_Fine(panels)).toFixed(3)
      //     : '0.000'
      // ),
      amount: rowData?.amount ? rowData.amount : '0.00',
      tunch: rowData?.tunch ? rowData.tunch : '100.000',
      fine: rowData?.fine ? rowData.fine : '0.000',
      rate: rowData?.rate ? rowData.rate : '0.00',
    })


    // console.log('asasdadas', add_edit);



    setPanelPopupState({
      visibility: true,
      type: add_edit
    })

  }






  // // param `key name`, val `new value` , index item's row index`, decimalCounting ` if you want to add value in 0.000`
  // const updateRowFunction = (param, val, index, decimalCounting) => {
  //   setPanels((prevData) => {
  //     const newData = [...prevData];
  //     newData[index] = {
  //       ...newData[index],
  //       [param]: decimalCounting || decimalCounting === 0 ? parseFloat(val).toFixed(decimalCounting) : (Array.isArray(val) ? val.name : val)
  //     };
  //     return newData;
  //   });
  // }
  // const updateRow2 = (param, val, index, decimalCounting) => {
  //   if (val !== '') {
  //     updateRowFunction(param, val, index, decimalCounting)
  //     checkToAutoSelectAndCalculationMethod(param, val, index, decimalCounting)
  //   }
  // }

  // const updateRow2_OneTime = (param, val, index, decimalCounting) => {
  //   if (val !== '') {
  //     updateRowFunction(param, val, index, decimalCounting)
  //   }
  // }

  // const checkToAutoSelectAndCalculationMethod = (param, val, index, decimalCounting) => {
  //   // console.log('checkToAutoSelectAndCalculationMethod');
  //   // console.log('param', param);
  //   // console.log('val', val);
  //   // console.log('index', index);
  //   // console.log('decimalCounting', decimalCounting);





  //   switch (param) {
  //     // case "item":
  //     //   if (val?.name) {
  //     //     const unitVal = allItemsData.find((item) => item.id === val.id)?.unit;
  //     //     updateRow2("unit", unitVal, index);
  //     //   }
  //     //   break;

  //     case "type":
  //       // Empty for now
  //       break;

  //     case "method":
  //       AutoFillValues_Amount_Fine_Rate(param, val, index, decimalCounting, panels, saleItems, updateRow2_OneTime)
  //       break;

  //     case "amount":
  //       AutoFillValues_Amount_Fine_Rate(param, val, index, decimalCounting, panels, saleItems, updateRow2_OneTime)
  //       break;

  //     case "grwt":
  //       AutoFillValues_Amount_Fine_Rate(param, val, index, decimalCounting, panels, saleItems, updateRow2_OneTime)
  //       break;

  //     case "tunch":
  //       AutoFillValues_Amount_Fine_Rate(param, val, index, decimalCounting, panels, saleItems, updateRow2_OneTime)
  //       break;

  //     case "fine":
  //       // Empty for now
  //       break;

  //     case "rate":
  //       AutoFillValues_Amount_Fine_Rate(param, val, index, decimalCounting, panels, saleItems, updateRow2_OneTime)
  //       break;

  //     case "total":
  //       // Empty for now
  //       break;

  //     default:
  //       break;
  //   }
  // };






  return (
    <>
      <MDBox display="flex" justifyContent="space-evenly" mb={1} >
        <TableContainer sx={{ borderRadius: 0 }}>
          <table cellPadding={0} cellSpacing={0} style={{ width: '100%', textAlign: 'left', border: '1px solid #d8d8d8', borderBottom: 'hidden' }}>
            <thead>
              <tr>
                <th><MDTypography variant="caption" fontWeight="medium" sx={{ width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Type </MDTypography></th>
                <th><MDTypography variant="caption" fontWeight="medium" sx={{ width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Method </MDTypography></th>
                <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Gr.Wt </MDTypography></th>
                <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Tunch </MDTypography></th>
                <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Fine </MDTypography></th>
                <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Rate </MDTypography></th>
                <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Amount </MDTypography></th>
                {/* <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Total </MDTypography></th> */}
                <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderBottom: '1px solid #d8d8d8' }}> &nbsp; </MDTypography></th>
              </tr>
            </thead>
            <tbody>


              {panels.map((val, i) => (
                <tr
                  // className='hoverable-row'
                  key={val.id}
                  onDoubleClick={() => {
                    handleToManageAddItemPopup(val, 'edit')
                  }}
                  style={{ cursor: 'pointer' }}

                  highlight_it={val.id}
                  onMouseEnter={() => { highlightSimilerRow(val.id) }}
                  onMouseLeave={() => { defaultSimilerRow(val.id) }}


                >


                  {/* <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowSelectBox
                      optionName="type"
                      data={val}
                      updateDataFunction={updateRow2}
                      options={transactionsTypeOption2}
                      indexCount={i}
                      itsMinWidth={90}
                    />
                  </td> */}
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle>
                      {val.type ? (
                        ConditionalCompare(val.method, '===', 'gold bhav') ? (
                          transactionsTypeOption3.find((item) => item.status === val.type)?.name || null
                        ) : (
                          transactionsTypeOption2.find((item) => item.status === val.type)?.name || null
                        )
                      ) : ''}
                    </TableInnerStyle>
                  </td>

                  {/* <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowSelectBox
                      optionName="method"
                      data={val}
                      updateDataFunction={updateRow2}
                      options={paymentMethodOption}
                      indexCount={i}
                      itsMinWidth={100}
                    />
                  </td> */}
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle>{val.method}</TableInnerStyle>
                  </td>

                  {/* <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="grwt"
                      data={val}
                      updateDataFunction={updateRow2}
                      indexCount={i}
                      decimalCounting={3}
                      maxLimit={5}
                      align="right"
                    />
                  </td> */}
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle align="right">{SetNumbersDecimals(val.grwt,3)}</TableInnerStyle>
                  </td>

                  {/* <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="tunch"
                      data={val}
                      updateDataFunction={updateRow2}
                      indexCount={i}
                      decimalCounting={2}
                      maxLimit={3}
                      align="right"
                    />
                  </td> */}
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle align="right">{SetNumbersDecimals(val.tunch,2)}</TableInnerStyle>
                  </td>

                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle align="right">
                      {SetNumbersDecimals(GetCalculationOf_Row2_Fine(val), 3)}
                    </TableInnerStyle>
                  </td>

                  {ConditionalCompare(val.method, '===', 'metal') ? (
                    <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                      <TableInnerStyle align="right">-</TableInnerStyle>
                    </td>
                  ) : (
                    <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                      <TableInnerStyle align="right">{SetFormattedIndianNumber(val.rate,2)}</TableInnerStyle>
                    </td>
                  )}


                  {ConditionalCompare(val.method, '!==', 'metal') ? (
                    // <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    //   <SaleItemRowInput
                    //     optionName="amount"
                    //     data={val}
                    //     updateDataFunction={updateRow2}
                    //     indexCount={i}
                    //     decimalCounting={2}
                    //     maxLimit={7}
                    //     align="right"
                    //   />
                    // </td>
                    <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                      <TableInnerStyle align="right">{SetFormattedIndianNumber(val.amount, 2)}</TableInnerStyle>
                    </td>
                  ) : (
                    <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                      <TableInnerStyle align="right">-</TableInnerStyle>
                    </td>
                  )}


                  {/* <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle align="right">
                      {SetFormattedIndianNumber(GetCalculationOf_Row2_Total(val), 2)}
                    </TableInnerStyle>
                  </td> */}

                  <td width={40} style={{ borderBottom: '1px solid #d8d8d8' }}>

                    <DeletButtonForTable onClick={() => { deleteRow2(i) }} >
                      <DeleteOutlineIcon
                        sx={{
                          width: '25px',
                          height: '25px',
                          fill: '#c80500'
                        }}
                      />
                    </DeletButtonForTable>
                  </td>

                </ tr>
              ))}





            </tbody>
          </table>
        </TableContainer>
      </MDBox>





      <MDBox mt={2} mb={2} gap={2} display="flex">
        <MDButton variant="outlined" size="small" color="primary" onClick={() => { handleToManageAddItemPopup({ type: "inflow", method: "cash" }, 'add') }}>Cash</MDButton>
        <MDButton variant="outlined" size="small" color="primary" onClick={() => { handleToManageAddItemPopup({ type: "inflow", method: "metal" }, 'add') }}>Metal</MDButton>
        <MDButton variant="outlined" size="small" color="primary" onClick={() => { handleToManageAddItemPopup({ type: "inflow", method: "gold bhav" }, 'add') }}>Gold Bhav</MDButton>
      </MDBox>








      <PanelPopup
        panelPopupState={panelPopupState}
        setPanelPopupState={setPanelPopupState}
        panelPopupData={panelPopupData}
        setPanels={setPanels}
        saleItems={saleItems}

      />


    </>
  )
}

export default SalePanelRow