

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDAlert from "components/MDAlert";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/gold-bg2.jpg";

import { useAuth } from "../../../utils/AuthProvider";


const axios = require('axios').default

function Cover() {

  const { login } = useAuth();

  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorSB, setErrorSB] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={errorMessage}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function setCookie(name, value, days) {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }

  
  function SignIn() {

    if(email!==''&&password!==''){
    axios
      .post('https://admin.goldshopper.in/api/loginapi', {
        user_email: email,
        user_password: password
      })
      .then( function (response) {
       console.log(response.data)
       if(response.data.status === 'login successful'){
        if(response.data.user_type === 'Admin' || response.data.user_type === 'Master' || response.data.user_type === 'Sub-Admin' || response.data.user_type === 'Marketing' || response.data.user_type === 'Support'){
          setCookie('user_email', response.data.user_email, 1)
          setCookie('user_type', response.data.user_type, 1)
          login(response.data.user_email);
          navigate("/dashboards/analytics");
          //window.location.href = ''
        }else{
          setErrorMessage('You are not allowed to access this portal. Please contact the administrator')
          openErrorSB()
          //alert('You are not allowed to access this portal. Please contact the administrator')
        }
       }else{
        setErrorMessage('Please check your credentials and try again.')
        openErrorSB()
        //alert('Please check your credentials and try again.')
       }

       //window.location.href = '/dashboards/analytics'
      })
      .catch(function (error) {
        setErrorMessage('Something went wrong')
        openErrorSB()
        console.log(error)
      })
    }else{
      //window.location.href = '/dashboards/analytics'
      setErrorMessage('Please enter the details to continue.')
      openErrorSB()
    }
    //window.location.href = '/dashboards/analytics'
    
  }

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="primary"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
                value={email} onChange={(e)=>setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                placeholder="************"
                InputLabelProps={{ shrink: true }}
                value={password} onChange={(e)=>setPassword(e.target.value)}
                onKeyPress={event => {
                  //console.log(event)
                if (event.key === 'Enter') {
                  SignIn()
                }
              }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton onClick={SignIn} variant="gradient" color="primary" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {renderErrorSB}
    </CoverLayout>
  );
}

export default Cover;
