import React, { Fragment, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';
import { Grid, TextField } from '@mui/material';

const CreateGroupPopup = ({ name, createFunction, size, rowName, rowId }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [validationForInvoiceName, setValidationForInvoiceName] = useState(false)







  const resetValueName = () => {
    rowName ? setValue(rowName) : setValue('')
  }

  const handleClickOpen = () => {
    setOpen(true);
    handleToFocusOnInputField()
  };

  const handleClose = () => {
    setOpen(false);
    resetValueName()
  };

  const handleSubmit = () => {
    const trimmedValue = value?.trim().replace(/\s+/g, ' ');
    const regex = /^\s*$/;
    if (regex.test(trimmedValue)) {
      setValidationForInvoiceName(true)
      return;
    }
    else {
      setValidationForInvoiceName(false)
      createFunction(trimmedValue, rowId);
      setOpen(false);
    }
  };

  // Focus On Copy Invoice Popup Input Field
  const handleToFocusOnInputField = () => {
    setTimeout(() => {
      const inputElement = document.querySelector('.group-add-popup .MuiOutlinedInput-input');
      if (inputElement) {
        inputElement.focus();
      }
    }, 200);
  };









  // Using To Update Invoice's Name on Template Switch
  useEffect(() => {
    rowName && setValue(rowName)
  }, [rowName])










  return (
    <Fragment>
      <MDButton variant="gradient" color="primary" size={size ? size : 'medium'} onClick={handleClickOpen}> {name} </MDButton>
      <Dialog
        className='group-add-popup'
        open={open}
        onClose={handleClose}
        aria-labelledby="group-popup-name"
        aria-describedby="copy-invoice-description"
        sx={{ maxWidth: '450px', margin: 'auto', '.MuiPaper-root': { padding: 2, margin: 0, width: '100%' } }}
      >
        <DialogTitle id="group-popup-name" sx={{ textAlign: 'center' }}>Kindly enter a name to proceed</DialogTitle>
        <DialogContent p={1}>
          <TextField
            value={value}
            autoFocus
            label="Name"
            error={validationForInvoiceName}
            helperText={validationForInvoiceName ? "Incorrect name" : ''}
            variant="outlined"
            onChange={(e) => { setValue(e.target.value) }}
            onKeyUp={(e) => {
              e.keyCode === 13 ? handleSubmit() : ''
            }}
            sx={{ width: '100%', marginTop: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} lg={12} justifyContent={"center"} display={"flex"} gap={1}>
            <MDButton variant="gradient" color="primary" size="small" onClick={handleSubmit} autoFocus>Create</MDButton>
            <MDButton variant="outlined" color="primary" size="small" onClick={handleClose}>Cancel</MDButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default CreateGroupPopup