import React from 'react'

const RoughInvoice = ({ contentToPrint }) => {

  
  return (
    <>

      <div style={{ width: '100%', maxWidth: '396px', margin: 'auto', border: '1px solid #b9b9b9', borderRadius: '10px' }}>
        <div ref={contentToPrint} style={{ width: '100%', padding: '10px' }}>
          <table cellPadding={0} cellSpacing={0} style={{ width: '100%', fontSize: '12px', lineHeight: 1.3, wordBreak: 'break-word' }}>
            <tbody>






              <tr>
                <td colSpan={6} style={{ paddingBottom: '5px', textAlign: 'center', fontSize: '120%', fontWeight: '500' }}>ROUGH ESTIMATE _________ ISSUE</td>
              </tr>


              <tr>
                <td>
                  <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: '1px solid #000', borderRadius: 5 }}>
                    <tbody>
                      <tr>
                        <td colSpan={4} style={{ padding: '5px 5px 3px', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Cash Customer</td>
                        <td colSpan={2} style={{ padding: '5px 5px 3px', fontWeight: '500' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ whiteSpace: 'pre' }}>Vou.No.:</div>
                            <div>7</div>
                          </div>
                        </td>
                      </tr>



                      <tr>
                        <td style={{ padding: '3px 5px 5px', borderBottom: '1px solid #000' }}></td>
                        <td colSpan={3} style={{ padding: '3px 5px 5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontWeight: '500' }}>Ph #</td>
                        <td colSpan={2} style={{ padding: '3px 5px 5px', borderBottom: '1px solid #000' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: '500' }}>
                            <div style={{ whiteSpace: 'pre' }}>Date : </div>
                            <div>18/09/2024</div>
                          </div>
                        </td>
                      </tr>



                      <tr>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000' }}></td>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Description</td>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Net Wt.</td>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Tnch+lbr</td>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}></td>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Amount</td>
                      </tr>



                      <tr>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>S</td>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>RJK 92RJK</td>
                        <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>4.030</td>
                        <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>92.00 + 4.50</td>
                        <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>3.889</td>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000' }}></td>
                      </tr>



                      <tr>
                        <td colSpan={4} style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Total &nbsp; Gold wt. &nbsp; 4.030</td>
                        <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>3.889</td>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000' }}></td>
                      </tr>



                      <tr>
                        <td style={{ padding: '5px' }}>MR</td>
                        <td style={{ padding: '5px' }}>Gold</td>
                        <td style={{ textAlign: 'right', padding: '5px' }}>-8.670</td>
                        <td style={{ textAlign: 'right', padding: '5px', borderRight: '1px solid #000' }}>100.00</td>
                        <td style={{ textAlign: 'right', padding: '5px', borderRight: '1px solid #000' }}>-8.670</td>
                        <td></td>
                      </tr>



                      <tr>
                        <td style={{ padding: '1px 5px' }}></td>
                        <td colSpan={3} style={{ padding: '1px 5px', borderRight: '1px solid #000' }}>P.GOLD BHAV @7420.00</td>
                        <td style={{ textAlign: 'right', padding: '1px 5px', borderRight: '1px solid #000' }}>4.781</td>
                        <td style={{ textAlign: 'right', padding: '1px 5px' }}>-35475</td>
                      </tr>



                      <tr>
                        <td style={{ padding: '5px', borderBottom: '1px solid #000' }}></td>
                        <td colSpan={3} style={{ padding: '5px', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>PAYMENT CASH</td>
                        <td style={{ padding: '5px', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}></td>
                        <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000' }}>35475</td>
                      </tr>



                      <tr>
                        <td colSpan={4} style={{ padding: '5px', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Closing Balance</td>
                        <td style={{ padding: '5px', borderRight: '1px solid #000' }}></td>
                        <td style={{ textAlign: 'right', padding: '5px' }}>35475</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>



              <tr>
                <td colSpan={6} style={{ paddingTop: '5px', textAlign: 'center', fontSize: '120%', fontWeight: '500' }}>User Name ABC1 (Time : 11:57:48 AM)</td>
              </tr>








            </tbody>
          </table>
        </div>
      </div>

    </>
  )
}

export default RoughInvoice