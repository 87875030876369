import React from 'react'

const Label = ({ contentToPrint }) => {


  return (
    <>

      <div style={{ padding: '20px 0' }}>
        <div style={{ width: '100%', maxWidth: '377px', margin: 'auto', border: '1px solid #b9b9b9', borderRadius: '10px', transform: 'scale(1.5)' }}>
          <div ref={contentToPrint} style={{ width: '100%', padding: '5px' }}>

            <div style={{ display: 'flex', alignItems: 'center', width: '100%', fontSize: '8px', fontWeight: 500, lineHeight: 1.3, wordBreak: 'break-word' }}>
              <div style={{ width: '118px', padding: '3px 4px', flexShrink: 0 }}>
                <div style={{ display: 'flex', gap: '3px 4px', alignItems: 'center' }}>
                  <img src="https://www.drupal.org/files/styles/grid-3-2x/public/project-images/qrcode-module_0.png" style={{ width: '40px', height: '40px', flexShrink: 0 }} />
                  <div>
                    <div>BILLA JEWELLERS DIA</div>
                    <div>NP-7332</div>
                  </div>
                </div>
              </div>
              <div style={{ width: '118px', padding: '3px 4px', flexShrink: 0 }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0 4px' }}>
                  <div>GWT: 3.160,</div>
                  <div>PC: 2,</div>
                  <div>Colour: J/SI,</div>
                  <div>750KDM,</div>
                  <div>CZ,</div>
                </div>
              </div>
              <div style={{ width: '132px', flexShrink: 0 }}>&nbsp;</div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default Label