import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from "./AuthProvider";

const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth();

  // return isAuthenticated ? <Outlet /> : <Outlet /> ;
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;