

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { addDays } from 'date-fns';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Data
import dataTableData from "layouts/reports/ledger/data/dataTableData";
import FormField from "layouts/account/components/FormField";


function Ledger() {
  const [menu, setMenu] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateopen, setDateOpen] = useState(false);
  const [customer, setCustomer] = useState('Select');

  const [datestate, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);
  const [origselection, setOrigSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  function resetParams() {
    
    setStartDate('')
    setEndDate('')
    setCustomer('Select')

    setDateState(origselection)
  }

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={1}>
          <Grid container spacing={1} alignItems="left">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Ledger
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      <MDBox my={3}>
        <Card>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2} p={2}>
        <Grid item xs={12} sm={8}>
          <MDBox display="flex" justifyContent="flex-start">
            <Autocomplete
                defaultValue="Select"
                value={customer}
                options={["Select","Karthik", "Arjun", "Shashank"]}
                style={{width:'23vw'}}
                onChange={(event, newValue) => {
                  console.log(newValue)
                  setCustomer(newValue)
                }}
                renderInput={(params) => <MDInput {...params} label="Customer" variant="standard" />}
              />
              <MDBox sx={{position:'relative'}}>
              
              <MDInput variant="standard" label="Select Date" value={startDate + ' - ' + endDate} placeholder="Select Range" onClick={()=>setDateOpen(!dateopen)} style={{width:'23vw', marginLeft:'1vw'}} type='text' />
              {dateopen?(
                <MDBox sx={{position:'absolute', zIndex:4, left:0, top:'3.5rem', padding:'2', backgroundColor:'#fff', borderRadius:'20px'}}>
                <DateRange
                    rangeColors={['#AF891F']}
                    minDate={addDays(new Date(), 0)}
                    
                    onChange={item => {
                      console.log(item.selection)
                      setDateState([item.selection])

                      var options = {year: 'numeric', month: 'short', day: 'numeric' };

                      setStartDate(new Date(item.selection.startDate).toLocaleDateString("en-US", options))
                      setEndDate(new Date(item.selection.endDate).toLocaleDateString("en-US", options))
                      if(item.selection.startDate !== item.selection.endDate){
                        setDateOpen(false)
                      }
                    }}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={datestate}
                    direction="vertical"
                  />
                  </MDBox>
              ):(<></>)}
                
              </MDBox>
            </MDBox>
            <MDBox mt={3}>
            <MDButton variant="gradient" color="primary">
            search
          </MDButton>
          <MDButton variant="gradient" color="dark" sx={{marginLeft:'10px'}} onClick={resetParams}>
            reset
          </MDButton>
            </MDBox>
          </Grid>

          {/*Summary */}

          <Grid item xs={12} sm={4} pl={2}>
          <MDBox mb={1}>
            <MDTypography variant="h6" fontWeight="medium">
              Summary
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="space-between" mb={0.2}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Opening Gold:
            </MDTypography>
            <MDBox ml={1}>
              <MDTypography variant="body2" fontWeight="medium">
                90
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="space-between" mb={0.2}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Closing Gold:
            </MDTypography>
            <MDBox ml={1}>
              <MDTypography variant="body2" fontWeight="medium">
                14
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="space-between" mb={0.2}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Opening Balance:
            </MDTypography>
            <MDBox ml={1}>
              <MDTypography variant="body2" fontWeight="medium">
                14
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="space-between" mb={0.2}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Closing Balance:
            </MDTypography>
            <MDBox ml={1}>
              <MDTypography variant="body2" fontWeight="medium">
                1.95
              </MDTypography>
            </MDBox>
          </MDBox>
          {/* <MDBox display="flex" justifyContent="space-between" mt={1}>
            <MDTypography variant="body1" fontWeight="light" color="text">
              Total:
            </MDTypography>
            <MDBox ml={1}>
              <MDTypography variant="body1" fontWeight="medium">
                1.95
              </MDTypography>
            </MDBox>
          </MDBox> */}
          </Grid>
          <MDBox>
          
          </MDBox>
          
        </MDBox>
        </Card>
      </MDBox>
      <MDBox my={3} onClick={()=>setDateOpen(false)}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDTypography></MDTypography>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>file_download</Icon>
                &nbsp;export pdf
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={dataTableData} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Ledger;
