

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import { addDays } from 'date-fns';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import FormField from "layouts/account/components/FormField";
// import { ElevatorSharp } from "@mui/icons-material";


// import { salePanelPaymentOption, paymentMethodOption } from "demo_data/const";
import SaleItemRow from "./components/SaleItemRow";
import { v4 as uuidv4 } from 'uuid';
// import { TableInnerStyle } from "../components/style";
import SalePanelRow from "../components/SalePanelRow";
// import { SetFormattedIndianNumber } from "components/globalFunctions";
import { ConditionalCompare, GetCalculationOf_ItemFine, GetCalculationOf_ItemTotal, GetCalculationOf_Netwt, GetCalculationOf_Row2_Fine, TotalCalculationOf_Fine, TotalCalculationOf_GrandFine, TotalCalculationOf_GrandTotal, TotalCalculationOf_ItemTotal } from "../components/functions";
import { TableContainer } from "@mui/material";
import GrandTotal from "../components/GrandTotal";







function Sale() {
  // const [menu, setMenu] = useState(null);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [selectDate, setSelectDate] = useState(new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }));
  const [setdate, setDate] = useState(new Date());
  const [bill, setBill] = useState(1);
  const [dateopen, setDateOpen] = useState(false);
  const [customer, setCustomer] = useState('Select');


  // const [saleItems, setSaleItems] = useState([{
  //   id: uuidv4(),
  //   item: null,
  //   type: "outflow",
  //   stamp: null,
  //   unit: null,
  //   pc: '0',
  //   grwt: "0.000",
  //   less: "0.000",
  //   netwt: '0.000',
  //   tunch: "0.00",
  //   wstg: '0.00',
  //   rate: '0.00',
  //   lbr: '0.00',
  //   on: 'Wt',
  //   fine: '0.000',
  //   total: '0.00',
  // }]);
  const [saleItems, setSaleItems] = useState([
    { "id": "46382a5f-9ef7-4143-aa82-31a85f8d8be2", "item": { "id": 1, "name": "tikka" }, "type": "outflow", "stamp": "24K", "unit": "Gm", "pc": "1", "grwt": "40.000", "less": "10.000", "netwt": "30.000", "tunch": "99.00", "wstg": "1.00", "rate": "0.00", "lbr": "100.00", "on": "Wt", "fine": "30.000", "total": "3000.00" },
    { "id": "2f239c62-f177-44ed-b2cc-16afcc5c1fc6", "item": { "id": 2, "name": "pendant set" }, "type": "outflow", "stamp": "18K", "unit": "Pc", "pc": "40", "grwt": "10.000", "less": "0.000", "netwt": "10.000", "tunch": "99.00", "wstg": "1.00", "rate": "1000.00", "lbr": "100.00", "on": "PC", "fine": "10.000", "total": "4000.00" }
  ]);

  // const [panels, setPanels] = useState([{
  //   id: uuidv4(),
  //   type: 'inflow',
  //   method: null,
  //   miaGoldBhav: true,
  //   bank: null,
  //   useAsGoldBhav: false,
  //   amount: '0.000',
  //   grwt: '0.000',
  //   tunch: '100.00',
  //   fine: '0.000',
  //   rate: '0.00',
  // }]);
  const [panels, setPanels] = useState([
    // { "id": "a03571c2-d396-4ac9-943a-1a1a857ab40d", "type": "inflow", "method": "Metal", "miaGoldBhav": true, "useAsGoldBhav": false,"amount": "0.000", "grwt": "26.264", "tunch": "100.00", "fine": "0.000", "rate": "0.00" },
    { "id": "610bc762-9908-446e-b8a8-c27a31049caf", "type": "inflow", "method": "Gold Bhav", "miaGoldBhav": false, "useAsGoldBhav": false, "amount": 15400, "grwt": 2.138888888888889, "tunch": "100.00", "fine": "2.138888888888889", "rate": 7200, "bank": null },
    { "id": "610bc761-9908-446e-b8a8-c27a31049caf", "type": "inflow", "method": "metal", "miaGoldBhav": true, "useAsGoldBhav": false, "amount": "0.00", "grwt": 20.428, "tunch": 92.67, "fine": "18.9306276", "rate": "0.00", "bank": null },
    { "id": "fb00c27f-bcae-4db8-84f1-ba9eea83747f", "type": "inflow", "method": "Cash", "miaGoldBhav": true, "useAsGoldBhav": false, "amount": 10000, "grwt": "0.000", "tunch": "100.00", "fine": "0", "rate": "0.00", "bank": null },
    { "id": "610bc761-9908-446e-b8a8-c27a31049cae", "type": "inflow", "method": "Cash", "miaGoldBhav": true, "useAsGoldBhav": true, "amount": 14600, "grwt": 2.0277777777777777, "tunch": "100.00", "fine": "2.0277777777777777", "rate": "7200.00", "bank": null }
  ]);


  const [grandTotal, setGrandTotal] = useState({
    id: uuidv4(),
    balance: {
      fine: "0.000",
      fine_type: null,
      total: "0.00",
      total_type: null,
    },

    sale_fine: "0.000",
    sale_total: "0.00",

    return_fine: "0.000",
    return_total: "0.00",

    sub_total: {
      fine: "0.000",
      fine_type: 'inflow',
      total: "0.00",
      total_type: 'inflow',
    },

    adjustments_fine: "0.0",
    adjustments_type: "percentage",
  });





  // const openMenu = (event) => setMenu(event.currentTarget);
  // const closeMenu = () => setMenu(null);






  function addRow() {

    console.log('saleItems', saleItems)
    var rowsNew = saleItems
    rowsNew.push(
      {
        id: uuidv4(),
        item: null,
        type: "outflow",
        stamp: null,
        unit: null,
        pc: '0',
        grwt: '0.000',
        less: '0.000',
        netwt: '0.000',
        tunch: '0.00',
        wstg: '0.00',
        rate: '0.00',
        lbr: '0.00',
        on: 'Wt',
        fine: '0.000',
        total: '0.00',
      }
    )

    console.log('addRow', rowsNew)

    setTimeout(() => {
      setSaleItems([])
      setSaleItems(rowsNew)
    }, 100);


    //console.log(table)
  }





  // function addRow2() {
  //   var rowsNew = panels
  //   rowsNew.push(
  //     {
  //       id: uuidv4(),
  //       type: 'inflow',
  //       method: '',
  //       miaGoldBhav: true,
  //       useAsGoldBhav: false,
  //       amount: '0.000',
  //       grwt: '0.000',
  //       tunch: '100.00',
  //       fine: '0.000',
  //       rate: '0.00',
  //     }
  //   )

  //   setTimeout(() => {
  //     setPanels([])
  //     setPanels(rowsNew)
  //   }, 100);

  // }


  function deleteRow(i) {
    if (saleItems.length <= 1) {
      deleteRowAction(i)
      addRow()
    }
    else {
      deleteRowAction(i)
    }
  }
  function deleteRowAction(i) {
    const index = i
    var saleitems = saleItems
    if (index > -1) {
      saleitems.splice(index, 1);
    }
    setTimeout(() => {
      setSaleItems([])
      setSaleItems(saleitems)
    }, 100);
  }



  function deleteRow2(i) {
    const index = i
    var panelitems = panels
    if (index > -1) {
      panelitems.splice(index, 1);
    }
    setTimeout(() => {
      setPanels([])
      setPanels(panelitems)
    }, 100);
  }
  // function deleteRow2Action(i) {
  //   const index = i
  //   var panelitems = panels
  //   if (index > -1) {
  //     panelitems.splice(index, 1);
  //   }
  //   setTimeout(() => {
  //     setPanels([])
  //     setPanels(panelitems)
  //   }, 100);
  // }




  function resetParams() {

    setSelectDate(new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }))
    setCustomer('Select')
    setBill('1')

    //setDateState(origselection)
  }












  const saveSaleData = () => {
    console.log('saveSaleData =================>');




    const updatedItems = saleItems.map((item) => ({
      ...item,
      netwt: GetCalculationOf_Netwt(item),
      fine: GetCalculationOf_ItemFine(item),
      total: GetCalculationOf_ItemTotal(item),
    }));
    setSaleItems(updatedItems);







    const updatedPanels = panels.map((item) => ({
      ...item,
      fine: GetCalculationOf_Row2_Fine(item),
    }));
    setPanels(updatedPanels);






    const updatedGrandTotal = {
      ...grandTotal,
      sale_fine: TotalCalculationOf_Fine(
        saleItems.filter((item) => ConditionalCompare(item.type, '===', 'outflow'))
      ),
      sale_total: TotalCalculationOf_ItemTotal(
        saleItems.filter((item) => ConditionalCompare(item.type, '===', 'outflow'))
      ),
      return_fine: Math.abs(Number(
        TotalCalculationOf_Fine(
          saleItems.filter((item) => ConditionalCompare(item.type, '===', 'inflow') || ConditionalCompare(item.type, '===', 'outinflow'))
        )
      )),
      return_total: Math.abs(Number(
        TotalCalculationOf_ItemTotal(
          saleItems.filter((item) => ConditionalCompare(item.type, '===', 'inflow') || ConditionalCompare(item.type, '===', 'outinflow'))
        )
      )),
      sub_total: {
        ...grandTotal.sub_total,
        fine: Math.abs(Number(TotalCalculationOf_GrandFine(saleItems, panels))),
        fine_type: TotalCalculationOf_GrandFine(saleItems, panels) <= 0 ? (TotalCalculationOf_GrandFine(saleItems, panels) === 0 ? null : "inflow") : "outflow",
        total: Math.abs(Number(TotalCalculationOf_GrandTotal(saleItems, panels))),
        total_type: TotalCalculationOf_GrandTotal(saleItems, panels) <= 0 ? (TotalCalculationOf_GrandTotal(saleItems, panels) === 0 ? null : "inflow") : "outflow",
      },
    };
    setGrandTotal(updatedGrandTotal);







    console.log('saleItems - Old', saleItems);
    console.log('saleItems - Updated', updatedItems);

    console.log('panels - Old', panels);
    console.log('panels - Updated', updatedPanels);

    console.log('grandTotal - Old:', grandTotal);
    console.log('grandTotal - Updated:', updatedGrandTotal);
  }



  const savePrintSaleData = () => {
    console.log('savePrintSaleData =================>');

  }

  const cancelSaleData = () => {
    console.log('cancelSaleDatav =================>');

  }














  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox mt={2} mb={1}>
        <Grid container spacing={1} alignItems="left">
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              Sale
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox> */}
      <MDBox my={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2} p={2}>
            <Grid item xs={12} sm={12}>
              <MDBox display="flex" justifyContent="flex-start">
                <Autocomplete
                  defaultValue="Select"
                  value={customer}
                  options={["Select", "Karthik", "Arjun", "Shashank"]}
                  style={{ width: '22vw' }}
                  onChange={(event, newValue) => {
                    console.log(newValue)
                    setCustomer(newValue)
                  }}
                  renderInput={(params) => <MDInput {...params} label="Customer" variant="standard" />}
                />
                <MDBox sx={{ position: 'relative' }}>

                  <MDInput variant="standard" label="Select Date" value={selectDate} placeholder="Select Date" onClick={() => setDateOpen(!dateopen)} style={{ width: '22vw', marginLeft: '1vw' }} type='text' />
                  {dateopen ? (
                    <MDBox sx={{ position: 'absolute', zIndex: 4, left: 0, top: '3.5rem', padding: '2', backgroundColor: '#fff', borderRadius: '20px' }}>
                      <Calendar
                        date={setdate}
                        onChange={item => {
                          console.log(item)
                          var options = { year: 'numeric', month: 'short', day: 'numeric' };
                          console.log(new Date(item).toLocaleDateString("en-US", options))
                          setSelectDate(new Date(item).toLocaleDateString("en-US", options))
                          setDate(item)
                          setDateOpen(false)
                        }}
                      />
                    </MDBox>
                  ) : (<></>)}
                  <MDInput variant="standard" label="Bill No" value={bill} onChange={(e) => setBill(e.target.value)} placeholder="Bill" style={{ width: '22vw', marginLeft: '1vw' }} type='number' />
                </MDBox>
                <MDButton variant="gradient" color="dark" style={{ marginLeft: '1vw' }} onClick={resetParams}>
                  reset
                </MDButton>
              </MDBox>
            </Grid>

            {/*Summary */}


            <MDBox>

            </MDBox>

          </MDBox>
        </Card>
      </MDBox>
      <MDBox my={3} onClick={() => setDateOpen(false)}>
        <Card>



          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={0} p={2}>
            <Grid item xs={12} sm={12}>









              <TableContainer sx={{ borderRadius: 0 }}>
                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', textAlign: 'left', border: '1px solid #d8d8d8', borderBottom: 'hidden' }}>
                  <tbody>
                    <tr display="flex" mb={1}>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Item </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Type </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Stamp </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Unit </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Pc </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Gr.Wt </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Less </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Net.Wt </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Tunch </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Wstg </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Rate </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Lbr </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> On </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Fine </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> Total </MDTypography></th>
                      <th><MDTypography variant="caption" fontWeight="medium" sx={{ width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderBottom: '1px solid #d8d8d8' }}> &nbsp; </MDTypography></th>
                    </tr>



                    <SaleItemRow
                      saleItems={saleItems}
                      setSaleItems={setSaleItems}
                      deleteRow={deleteRow}
                    />








                  </tbody>
                </table>
              </TableContainer>









            </Grid>
          </MDBox>
























          <MDBox>
            <MDButton variant="outlined" size="small" color="primary" style={{ marginLeft: '1vw' }} onClick={addRow}> add row </MDButton>
          </MDBox>












































          {/* <DataTable table={table} entriesPerPage={false} canSearch /> */}
          <MDBox mb={2}>


            {/* Panels */}


            <MDBox display="flex" justifyContent="space-evenly" p={2}>
              <Grid item sm={7} xs={7}>





                <SalePanelRow
                  panels={panels}
                  setPanels={setPanels}
                  saleItems={saleItems}
                  deleteRow2={deleteRow2}
                />


                {/* <MDBox mb={2}>
                  <MDButton variant="outlined" size="small" color="primary" onClick={addRow2}> add row </MDButton>
                </MDBox> */}
              </Grid>































































              <Grid item sm={5} xs={5} ml={2}>

                <GrandTotal
                  saleItems={saleItems}
                  panels={panels}
                  grandTotal={grandTotal}
                />
              </Grid>


            </MDBox >
            <MDBox display="flex" justifyContent="center" mb={2}>
              <MDButton onClick={() => { saveSaleData() }} variant="outlined" color="dark"> Save </MDButton>
              <MDButton onClick={() => { savePrintSaleData() }} variant="gradient" color="primary" sx={{ marginLeft: '10px' }}> Save & Print </MDButton>
              <MDButton onClick={() => { cancelSaleData() }} variant="outlined" color="error" sx={{ marginLeft: '10px' }}> Cancel </MDButton>
            </MDBox>
          </MDBox >






























        </Card >
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}

export default Sale;
