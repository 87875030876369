import IdCell from "./IdCell";
import DefaultCell from "./DefaultCell";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { NavLink } from "react-router-dom";


const dataTableData = {
  columns: [
    {
      Header: "Prefix",
      accessor: "prefix",
      width: 100,
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Group",
      accessor: "group_category",
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Unit",
      accessor: "unit",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Labour Upon",
      accessor: "labour_upon",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Stock Method",
      accessor: "stock_method",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Actions",
      accessor: "actions",
      width: 10,
      align: "right",
      Cell: ({ row }) => (
        <MDBox display="flex" gap={2}>
          <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-item/${row.original.id}`}>Edit</MDButton>
          <MDButton size="small" variant="outlined" color="primary">Delete</MDButton>
        </MDBox>
      ),
    },
  ],

  rows: [],
};

export default dataTableData;
