import { useState } from "react";

import { Autocomplete, Popper, Paper, styled } from '@mui/material'; // Make sure Popper is imported here
import MDInput from 'components/MDInput'
import React from 'react'












const SaleItemRowSelectBox = ({
  optionName,
  data,
  updateDataFunction,
  options,
  indexCount = 0,
  itsWidth,
  itsMinWidth,
  handleToManageAddItemPopup,
  align,
  label = '',
  inputStyle = false,
  autoFocus = false
}) => {

  const [open, setOpen] = useState(false);


  // Styled Paper Component for further control over options
  const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    "& .MuiAutocomplete-option": {
      "&[data-focus='true']": {
      },
      "&[aria-selected='true']": {

      },
    },
  }));


  // Styled Popper Component for Popup Customization
  const StyledPopper = styled(Popper)(({ theme }) => ({
    padding: '0 !important',
    // width: 'auto !important',

    "& .MuiPaper-root": {
      padding: '0 !important',
    },
    "& .MuiPaper-root .MuiAutocomplete-option": {
      padding: '0.3rem 0.5rem !important',
      textTransform: 'capitalize',
      justifyContent: align === "right" ? "flex-end" : "initial",
      whiteSpace: 'pre'
    },
    "& .MuiPaper-root .MuiAutocomplete-option.Mui-focused": {
      backgroundColor: '#f6f1e4 !important',
      color: "#344767 !important"
    },
    "& .MuiPaper-root .MuiAutocomplete-option:hover": {
      backgroundColor: '#f6f1e4 !important',
      color: "#344767 !important"
    },
    "& .MuiPaper-root .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: '#f6f1e4 !important',
      color: "#344767 !important"
    },


  }));


  // let selectedOption = options.find((item) => item.status === data[optionName])?.name || null
  let selectedOption = options.find((item) => item.status === data[optionName]) || null;



  return (
    <>


      <Autocomplete
        popupIcon={''}
        freeSolo={false}


        value={
          optionName === 'type' ? (
            selectedOption = options.find((item) => item.status === data[optionName]) || null
          ) : data[optionName]
        }
        options={options}
        getOptionLabel={(innerOptions) => options[0].name ? innerOptions?.name : innerOptions}
        autoHighlight

        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onFocus={() => setOpen(true)}

        onChange={(_, newValue) => {
          if (newValue?.name === "Add Item") {
            updateDataFunction(optionName, "", indexCount);
            handleToManageAddItemPopup()
          } else {
            updateDataFunction(optionName, newValue, indexCount);
          }
        }}
        sx={{
          width: itsWidth ? `${itsWidth}px` : 'auto',
          minWidth: itsMinWidth ? `${itsMinWidth}px` : 'auto',
          height: '40px',

          "& input": {
            textTransform: 'capitalize',
            textAlign: align ? align : "initial",
          },

          ...(!inputStyle && {
            "& .MuiInput-root:before": { display: 'none' },
            "& .MuiInput-root:after": { display: 'none' },
            "& .MuiAutocomplete-endAdornment": { display: 'none' },
            "&.Mui-focused": { background: '#f6f1e4' },
          }),

          ...(inputStyle && {
            "& .MuiFormControl-root": { padding: 0, height: '40px', },
            "& .MuiOutlinedInput-root": { padding: '0px 15px 0 8px !important', height: '40px', },
            "& .MuiOutlinedInput-input": { fontWeight: 600, },
          }),


        }}
        PopperComponent={StyledPopper} // Use the styled Popper
        PaperComponent={StyledPaper} // Use the styled Paper for further customizations
        renderInput={(params) => (
          <MDInput
            sx={{
              padding: '5px 10px',
              '& .MuiInput-root': {
                paddingRight: '0 !important',
                fontWeight: 600,
              },
            }}
            {...params}
            variant={inputStyle ? inputStyle : "standard"}
            label={label}
            autoFocus={autoFocus}
            placeholder="None"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
      />
    </>
  )
}

export default SaleItemRowSelectBox