// ProductsList page components
import IdCell from "./IdCell";
import DefaultCell from "./DefaultCell";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import GroupPopup from "../../components/GroupPopup";



const handleToEditAccountGroup = (name, rowId) => {
  console.log('handleToEditAccountGroup', name, rowId)

  axios
    .post('https://admin.goldshopper.in/api/addaccountgroup', {
      id: rowId,
      name: name,
      created_at: new Date(),
    })
    .then( function (response) {
     console.log(response.data)
     if(response.data === 'done'){
      alert('Account Group Updated')
     }
     //window.location.href = '/dashboards/analytics'
    })
    .catch(function (error) {
      alert('Something went wrong')
      //openErrorSB()
      console.log(error)
    })

}



const dataTableData = {
  columns: [
    {
      Header: "S/N",
      accessor: "id",
      width: 50,
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 200,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Created At",
      accessor: "created_at",
      width: 200,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Actions",
      accessor: "actions",
      width: 10,
      align: "right",
      Cell: ({ row }) => (
        <MDBox display="flex" gap={2}>
          <GroupPopup
            name="Edit"
            size="small"
            createFunction={handleToEditAccountGroup}
            rowName={row.original.name}
            rowId={row.original.id}
          />
          <MDButton size="small" variant="outlined" color="primary">Delete</MDButton>
        </MDBox>
      ),
    },
  ],
  rows: [],
};

export default dataTableData;
