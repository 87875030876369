import React, { useState, useEffect } from "react";
import MDInput from 'components/MDInput'
import styled from '@emotion/styled'
import { SetNumbersDecimals } from "components/globalFunctions";


const TooltipStyle = styled.div`
  position: absolute;
  left: calc(100% - 2px);
  top: 0;
  bottom: 0;
  height: 32px;
  background: #f6f1e4;
  font-size: 14px;
  display: flex;
  padding: 0 8px;
  margin: auto;
  align-items: center;
  z-index: 1;
  border-radius: 4px;
  white-space: pre;
  font-weight: 500;
  color: #000;
  border: 1px solid #dad4c4;
  letter-spacing: 0;

  &:before, &:after{
    content: '';
    height: 10px;
    width: 10px;
    border-left: none;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 100%;
  }
  &:before{
    border: 7px solid transparent;
    border-right-color: #dad4c4;
  }
  &:after{
    border: 6px solid transparent;
    border-right-color: #f6f1e4;
  }
`





const SaleItemRowInput = ({
  optionName,
  data,
  updateDataFunction,
  indexCount = 0,
  itsWidth,
  itsMinWidth,
  decimalCounting,
  maxLimit,
  align,
  toolTipData,
  label = '',
  inputStyle = false,
  autoFocus = false
}) => {

  const [tempValue, setTempValue] = useState(data && data[optionName] ? data[optionName] : '')
  const [tempValueActive, setTempValueActive] = useState(false)
  const [tooltipVisibility, setTooltipVisibility] = useState(false)

  let defaultValue = tempValueActive ? tempValue : SetNumbersDecimals(data[optionName], decimalCounting)


  useEffect(() => {
    setTempValue(data[optionName] || '')
  }, [data])



  const handelToRowForThreeDigits = (param, val, index, decimalCount) => {
    if (decimalCount === null || decimalCount === undefined) {
      setTempValueActive(true)
      let value = Number(val.target.value);

      if (value === 0) {
        setTempValue('');
        return;
      }

      const regex = new RegExp(`^(?!0{2,})(0|[1-9]\\d{0,${(maxLimit ? maxLimit : 0) - 1}})(\\.\\d{0,${decimalCounting ? decimalCounting : 0}})?$`);
      if (regex.test(value) || value === '') {
        // value = value;
        setTempValue(value)
      }
    }
    else {
      setTempValueActive(false)
      updateDataFunction(param, (tempValue === '' ? 0 : tempValue), index, decimalCounting);
    }
  }


  const handleToFocusAllText = (e) => {
    e.target.select();
  };

  const handleToDisableKeyCounting = (e) => {
    // Prevent default behavior for arrow keys
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };














  return (
    <div style={{ position: 'relative' }}>
      <MDInput
        sx={{
          width: itsWidth ? `${itsWidth}px` : '100%',
          minWidth: itsMinWidth ? `${itsMinWidth}px` : 'auto',
          backgroundColor: 'transparent',
          height: '40px',

          "& .MuiInput-root": {
            height: '100%',
          },
          "& .MuiInput-input": {
            height: '100%',
            textAlign: align || "left",
            fontWeight: 600,
            padding: '0 10px'
          },

          ...(!inputStyle ? {
            '& .Mui-focused': { background: '#f6f1e4' },
            "& .MuiInput-root:before": { display: 'none' },
            "& .MuiInput-root:after": { display: 'none' },
          } : {
            "& input": {
              height: '100%',
              textAlign: align || "left",
              fontWeight: 600,
              padding: '0 10px',
              height: '44px'
            },
          }),



        }}
        variant={inputStyle ? inputStyle : "standard"}
        label={label}
        onFocus={(e) => { handleToFocusAllText(e); setTooltipVisibility(true) }}
        onKeyDown={(e) => { handleToDisableKeyCounting(e) }}
        value={defaultValue}
        onBlur={(e) => { handelToRowForThreeDigits(optionName, e, indexCount, decimalCounting); setTooltipVisibility(false) }}
        onChange={(e) => { handelToRowForThreeDigits(optionName, e, indexCount) }}
        inputProps={{
          type: "number",
        }}
        autoFocus={autoFocus}
      />
      {toolTipData && tooltipVisibility ? (
        <TooltipStyle>{toolTipData}</TooltipStyle>
      ) : ("")}

    </div>
  )
}

export default SaleItemRowInput