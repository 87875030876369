import MDTypography from "components/MDTypography";

const DefaultCell = ({ value }) => {
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {value}
    </MDTypography>
  );
}



export default DefaultCell;
