

export const groupCategoryOption = ["Gold 14K", "Diamond", "Silver", "KDM", "Gold 18K", "Gold 24K", "Gold 20K", "Gold 22K"];
export const unitOption = ["Gm", "Kg", "Pc", "Carat", "Ratti", "Cent"];
// export const labourOption = ["Wt", "Rs", "%", "m%", "tot%", "Gm"];
export const labourOption = ["Wt", "PC", "Rs", "%", "tot%"];

export const stockMethodOption = ["Default", "Wholesale"];
export const groupOption = ["Retailer", "Item wise"];
// export const stockMethodOption = [
//   { label: 'default', value: 'default' },
//   { label: 'item wise', value: 'item_wise' },
// ];

export const methodOption = ["Cr", "Dr"];
export const stampTypeOption = ["24K", "23K", "22K", "18K", "14K", "10K"];


// export const transactionsTypeOption = ["sale", "purchase", "sale return"];
// export const transactionsTypeOption2 = ["payment", "receipt"];


export const transactionsTypeOption = [
  { id: 1, name: "sale", status: "outflow" },
  { id: 2, name: "purchase", status: "inflow" },
  { id: 3, name: "sale return", status: "outinflow" }
];
export const transactionsTypeOption2 = [
  { id: 1, name: "payment", status: "outflow" },
  { id: 2, name: "receipt", status: "inflow" },
];
export const transactionsTypeOption3 = [
  { id: 2, name: "purchase", status: "inflow" },
  { id: 1, name: "sale", status: "outflow" },
];



export const bankAccountsOptions = [
  { id: 1, name: "HDFC", label: "hdfc" },
  { id: 2, name: "ICICI", label: "icici" },
  { id: 3, name: "State Bank of India", label: "sbi" },
  { id: 4, name: "Axis Bank", label: "axis" },
  { id: 5, name: "Kotak Mahindra Bank", label: "kotak" },
  { id: 6, name: "Punjab National Bank", label: "pnb" },
  { id: 7, name: "Bank of Baroda", label: "bob" },
  { id: 8, name: "Union Bank of India", label: "union" },
  { id: 9, name: "IDFC First Bank", label: "idfc" },
  { id: 10, name: "Yes Bank", label: "yes" }
];





// export const salePanelPaymentOption = ["Panel", "purchase", "Payment", "Gold Bhav", "Metal purchase", "Metal sale"];
// export const paymentMethodOption = ["Cash","Cheque","Card"];
export const paymentMethodOption = ["metal", "cash", "cheque", "card", "gold bhav"];

