const form = {
  formId: "itemForm",
  formField: {
    name: { name: "name", label: "Name" },
    group_category: { name: "group_category", label: "Group/Category" },
    prefix: { name: "prefix", label: "Prefix" },
    unit: { name: "unit", label: "Unit" },
    labour_upon: { name: "labour_upon", label: "Labour upon" },
    stock_method: { name: "stock_method", label: "Stock method" },
    individual_price: { name: "individual_price", label: "Individual Price" },

    gold_fine: { name: "gold_fine", label: "Gold fine" },
    gold_fine_method: { name: "gold_fine_method", label: "Method" },

    silver_fine: { name: "silver_fine", label: "Silver fine" },
    silver_fine_method: { name: "silver_fine_method", label: "Method" },

    amount: { name: "amount", label: "Amount" },
    amount_method: { name: "amount_method", label: "Method" },
  },
};

export default form;
