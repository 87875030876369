

import { useState, useEffect } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

//  React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditUser layout schemas for form and form feilds
import validations from "layouts/users/edit-user/schemas/validations";
import form from "layouts/users/edit-user/schemas/form";

import { useParams } from "react-router-dom";
import { EmailRounded } from "@mui/icons-material";
import FormField from "./components/FormField";
import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";

const axios = require('axios').default

function EditUser() {
  const [activeStep, setActiveStep] = useState(0);

  const { formId, formField } = form;
  const currentValidation = validations[activeStep];

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    role: "Customer",
    email: "",
});

  const { email } = useParams();


  useEffect(() => {
    axios
      .post('https://admin.goldshopper.in/api/getuserdata', {
        user_email:email
      })
      .then( function (response) {
       console.log(response.data)
       if(response.data.status === 'user found'){
        const rowData = response.data.user
        //const rowData = rowDataJson.find((item) => item.id === parseInt(id));

        const tempInitialValues = {
          firstName: rowData ? rowData.user_first_name : '',
          lastName: rowData ? rowData.user_last_name : '',
          phone: rowData ? rowData.user_number : '',
          email: rowData ? rowData.user_email : '',
          role: rowData ? rowData.user_type : '',
        };

        setInitialValues(tempInitialValues);

       }
      })
      .catch(function (error) {
        alert('Something went wrong')
        console.log(error)
      })
    

  }, [email]); // Add email to the dependency array


  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    await sleep(1000);
    console.log(values)

    //var user_id = randomString(13)

    axios
      .post('https://admin.goldshopper.in/api/saveusertype', {
        user_first_name: values.user_first_name,
        user_last_name: values.user_last_name,
        user_email: email,
        user_type: values.user_type,
        user_number: values.user_number,
      })
      .then( function (response) {
       console.log(response.data)
       if(response.data === 'done'){
        alert('User Updated')
        actions.setSubmitting(false);
        actions.resetForm();
       }

       //window.location.href = '/dashboards/analytics'
      })
      .catch(function (error) {
        alert('Something went wrong')
        //openErrorSB()
        console.log(error)
      })
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    // if (isLastStep) {
    //   submitForm(values, actions);
    // } else {
    //   setActiveStep(activeStep + 1);
    //   actions.setTouched({});
    //   actions.setSubmitting(false);
    // }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
          <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card autoComplete="off" sx={{ height: "100%" }}>

                    {initialValues.firstName ? (
                      <MDBox p={3} pb={0}>
                        <MDTypography variant="h5">Edit - {initialValues.firstName}</MDTypography>
                      </MDBox>
                    ) : ''}

                    <MDBox p={3}>
                      <MDBox component="form" pb={3}>
                        <Grid container spacing={3}>




                          <Grid item xs={12} sm={6}>
                            <FormField
                              name={formField.firstName.name}
                              label={formField.firstName.label}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormField
                              name={formField.lastName.name}
                              label={formField.lastName.label}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              options={['Admin', 'Customer']}
                              value={values.role || null}
                              onChange={(event, newValue) => {
                                setFieldValue("role", newValue);
                              }}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <MDInput
                                  {...params}
                                  label={formField.role.label}
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormField
                              disabled
                              name={formField.email.name}
                              label={formField.email.label}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormField
                              name={formField.phone.name}
                              label={formField.phone.label}
                            />
                          </Grid>


                        </Grid>
                      </MDBox>
                      <MDBox mt={2} gap={2} width="100%" display="flex" justifyContent="flex-start">
                        <MDButton type="submit" variant="gradient" color="primary" disabled={isSubmitting}>Save</MDButton>
                        {/* <MDButton type="reset" variant="gradient" color="dark">Reset</MDButton> */}
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditUser;
