
import styled from '@emotion/styled'


export const TableInnerStyle = styled.p`
  font-size: 14px;
  padding: 5px 10px;
  white-space: pre;
  font-weight: 600;
  height: 40px;
  align-items: center;
  text-transform: capitalize;
  display: flex;
  justify-content: ${(props) => props.align ? props.align : 'left'};
  width: ${(props) => props.itsWidth ? `${props.itsWidth}px` : 'auto'};

  color: ${(props) => props.color ? props.color : 'initial'};

  

  
`
export const TableInnerErrorStyle = styled.p`
  font-size: 10px;
  padding: 5px 10px;
  font-weight: 600;
  color: #be0000;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  
  text-align: ${(props) => props.align ? props.align : 'left'};
`
export const DeletButtonForTable = styled.p`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`



export const InputBoxReplicaStyle = styled.div`
    /* background: rgb(0 0 0 / 5%); */
    border: 1px solid #d2d6da;
    border-radius: 6px;
    position: relative;
    background: #f5f5f5;

  label{
    font-weight: 400;
    letter-spacing: 0.00938em;
    display: block;
    color: #7b809a;
    font-size: 12px;
    line-height: 1.5;
    position: absolute;
    top: -10px;
    left: 7px;
    background: #fff;
    padding: 0px 6px;
    border-radius: 10px;
  }
  p{
    height: 100%;
    text-align: left;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 42px;
  }

`






 







