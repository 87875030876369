import IdCell from "./IdCell";
import DefaultCell from "./DefaultCell";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { NavLink } from "react-router-dom";


const dataTableData = {
  columns: [
    {
      Header: "S/N",
      accessor: "id",
      width: 100,
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 300,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Created By",
      accessor: "created_by",
      width: 300,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Created At",
      accessor: "created_at",
      width: 300,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Actions",
      accessor: "actions",
      width: 10,
      align: "right",
      // Cell: (
      //   <MDBox display="flex" gap={2}>
      //     <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-account/${row.original.id}`}>Edit</MDButton>
      //     <MDButton size="small" variant="outlined" color="primary">Delete</MDButton>
      //   </MDBox>
      // ),
      Cell: ({ row }) => (
        <MDBox display="flex" gap={2}>
          <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-account/${row.original.id}`}>Edit</MDButton>
          <MDButton size="small" variant="outlined" color="primary">Delete</MDButton>
        </MDBox>
      ),
    },
  ],

  rows: [],
};

export default dataTableData;
