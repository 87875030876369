// @mui material components
import Card from "@mui/material/Card";
//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Grid } from "@mui/material";

// Data
import Accounts from "./accounts";
import GroupPopup from "./components/GroupPopup";

const axios = require('axios').default

const Account_Group = () => {


  var randomString = function (len, bits) {
    bits = bits || 36
    var outStr = '',
      newStr
    while (outStr.length < len) {
      newStr = Math.random().toString(bits).slice(2)
      outStr += newStr.slice(0, Math.min(newStr.length, len - outStr.length))
    }
    return outStr.toUpperCase()
  }

  const handleToCreateAccountGroup = (name) => {
    console.log('handleToCreateAccountGroup', name)

    var accountID = randomString(10)

    axios
      .post('https://admin.goldshopper.in/api/addaccountgroup', {
        id: accountID,
        name: name,
        created_at: new Date(),
      })
      .then( function (response) {
       console.log(response.data)
       if(response.data === 'done'){
        alert('Account Group Added')
       }
       //window.location.href = '/dashboards/analytics'
      })
      .catch(function (error) {
        alert('Something went wrong')
        //openErrorSB()
        console.log(error)
      })

  }
  

  return (
    <DashboardLayout>
      <DashboardNavbar />

        <Grid py={3} px={0}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <MDTypography variant="h5" fontWeight="medium">Account Groups</MDTypography>
            <GroupPopup
              name="Create New Account Group"
              createFunction={handleToCreateAccountGroup}
              size="medium"
            />
          </MDBox>
          <Card>
            <Accounts />
          </Card>
        </Grid>




      <Footer />
    </DashboardLayout>
  );
}

export default Account_Group;