import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function GirviDashboard() {
  const [name, setName] = useState('');

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox mt={6} mb={8} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    Girvi Dashboard
                  </MDTypography>
                </MDBox>
                <MDTypography variant="h5" fontWeight="regular" color="secondary">
                  lorem ipsum
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GirviDashboard;
