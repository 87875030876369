// import { useState } from "react";
import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import NewUser layout schemas for form and form fields
// Assuming form and validations are already defined in schemas (fix imports accordingly)
import validations from "./components/validations";
import form from "./components/form";
import initialValues from "./components/initialValues";

import FormField from "./components/FormField";
import { Autocomplete, Divider } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { groupCategoryOption } from "demo_data/const";
import { methodOption } from "demo_data/const";

const axios = require('axios').default

function AddAccount() {
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

    var randomString = function (len, bits) {
      bits = bits || 36
      var outStr = '',
        newStr
      while (outStr.length < len) {
        newStr = Math.random().toString(bits).slice(2)
        outStr += newStr.slice(0, Math.min(newStr.length, len - outStr.length))
      }
      return outStr.toUpperCase()
    }

  //This function is to get any cookie saved
  function getCookie(name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  const submitForm = async (values, actions) => {
    await sleep(1000);
    console.log(values);

    var accountID = randomString(10)
    var owner = getCookie('user_email')

    axios
      .post('https://admin.goldshopper.in/api/addaccount', {
          id: accountID,
          group: values.group,
          name: values.name,
          contact_no: values.contact_no,
          alternate_contact_no: values.alternate_contact_no,
          date_of_birth: values.date_of_birth,
          address: values.address,
          gold_fine_method: values.gold_fine_method,
          created_by: owner,
          gold_fine: values.gold_fine,
          silver_fine_method: values.silver_fine_method,
          silver_fine: values.silver_fine,
          amount_method: values.amount_method,
          amount: values.amount,
          created_at: new Date(),
      })
      .then( function (response) {
       console.log(response.data)
       if(response.data === 'done'){
        alert('Account Added')
        actions.setSubmitting(false);
        actions.resetForm();
       }

       //window.location.href = '/dashboards/analytics'
      })
      .catch(function (error) {
        alert('Something went wrong')
        //openErrorSB()
        console.log(error)
      })

  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };















  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={2}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form id={formId}>
                  <Card autoComplete="off" sx={{ height: "100%" }}>

                    <MDBox p={3} pb={0}>
                      <MDTypography variant="h5">Add a new Account</MDTypography>
                    </MDBox>

                    <MDBox p={3}>
                      <MDBox component="form" pb={2}>
                        <Grid container spacing={3}>





                          <Grid item xs={12} sm={3}>
                            <Autocomplete
                              options={groupCategoryOption}
                              renderInput={(params) => <MDInput {...params} label={formField.group.label} variant="standard" />}
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <FormField
                              name={formField.name.name}
                              label={formField.name.label}
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <FormField
                              name={formField.contact_no.name}
                              label={formField.contact_no.label}
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <FormField
                              name={formField.alternate_contact_no.name}
                              label={formField.alternate_contact_no.label}
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            {/* <FormField
                            name={formField.date_of_birth.name}
                            label={formField.date_of_birth.label}
                          /> */}

                            <FormField
                              name={formField.date_of_birth.name}
                              label={formField.date_of_birth.label}
                              type="date"
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <FormField
                              name={formField.address.name}
                              label={formField.address.label}
                            />
                          </Grid>





                          {/* <Grid item xs={12} sm={12}>
                          <Divider style={{ height: 2, background: '#d2d6da' }} />
                        </Grid> */}

                          <Grid item xs={12} sm={12} mt={5}>
                            <MDTypography variant="h5">Opening Balance Amount</MDTypography>
                          </Grid>




                          <Grid container item spacing={3}>
                            <Grid display="flex" gap={2} item xs={12} sm={3}>
                              <Autocomplete
                                style={{ width: '100px' }}
                                options={methodOption}
                                renderInput={(params) => <MDInput {...params} label={formField.gold_fine_method.label} variant="standard" />}
                              />
                              <FormField
                                name={formField.gold_fine.name}
                                label={formField.gold_fine.label}
                              />
                            </Grid>


                            <Grid display="flex" gap={2} item xs={12} sm={3}>
                              <Autocomplete
                                style={{ width: '100px' }}
                                options={methodOption}
                                renderInput={(params) => <MDInput {...params} label={formField.silver_fine_method.label} variant="standard" />}
                              />
                              <FormField
                                name={formField.silver_fine.name}
                                label={formField.silver_fine.label}
                              />
                            </Grid>


                            <Grid display="flex" gap={2} item xs={12} sm={3}>
                              <Autocomplete
                                style={{ width: '100px' }}
                                options={methodOption}
                                renderInput={(params) => <MDInput {...params} label={formField.amount_method.label} variant="standard" />}
                              />
                              <FormField
                                name={formField.amount.name}
                                label={formField.amount.label}
                              />
                            </Grid>
                          </Grid>







                        </Grid>
                      </MDBox>
                      <MDBox mt={2} gap={2} width="100%" display="flex" justifyContent="flex-start">
                        <MDButton type="submit" variant="gradient" color="primary" disabled={isSubmitting}> Create New Account </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default AddAccount;
