import Card from "@mui/material/Card";

import { useEffect, useState } from "react";
//  React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "../../../components/DataTable";

// Data
import dataTableData from "./components/dataTableData";
import NoDataFound from "components/NoDataFound";
import rowDataJson from "../../../demo_data/itemsData.json";
import { NavLink } from "react-router-dom";

const axios = require('axios').default

const Items = () => {

  const [data, setData] = useState({ ...dataTableData, rows: [] });

  useEffect(() => {
    axios
      .post('https://admin.goldshopper.in/api/getitemslistfetch', {
      })
      .then( function (response) {
       console.log(response.data)
       setData(prevData => ({ ...prevData, rows: response.data }));
       //window.location.href = '/dashboards/analytics'
      })
      .catch(function (error) {
        console.log('Something went wrong')
        console.log(error)
      })
    ///setData(prevData => ({ ...prevData, rows: rowDataJson }));
  }, [dataTableData]);




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="flex-end" alignItems="center" gap={2} mb={2}>
          <MDButton variant="gradient" color="primary" component={NavLink} to="/master/add-item"> Create Item </MDButton>
          <MDButton variant="gradient" color="primary"> Create Item (CSV ) </MDButton>
        </MDBox>
        <Card>
          {
            data.rows.length > 0 ? (
              <DataTable table={data} canSearch />
            ) : (
              <MDBox p={2}>
                <NoDataFound
                  title="No Data Found"
                />
              </MDBox>
            )
          }
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Items;
