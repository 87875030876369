



// Conver Number Into Indian Format `123456 => 1,23,456`
export const SetFormattedIndianNumber = (number, decimalCounting) => {
  const formattedNumber = new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: decimalCounting,
    maximumFractionDigits: decimalCounting
  }).format(number);

  return formattedNumber
}

 
export const SetNumbersDecimals = (number, decimalCounting) => {
  return Number(number).toFixed(decimalCounting);
}


