import { useEffect, useState } from "react";
import DataTable from "../../../../components/DataTable";

// Data
import rowDataJson from "../../../../demo_data/accountGroupsData.json";
import dataTableData from "./components/dataTableData";
import MDBox from "components/MDBox";
import NoDataFound from "components/NoDataFound";

const axios = require('axios').default

const Accounts = () => {
  const [data, setData] = useState({ ...dataTableData, rows: [] });

  useEffect(() => {
    axios
      .post('https://admin.goldshopper.in/api/getaccountgroupslistfetch', {
      })
      .then( function (response) {
       console.log(response.data)
       setData(prevData => ({ ...prevData, rows: response.data }));
       //window.location.href = '/dashboards/analytics'
      })
      .catch(function (error) {
        console.log('Something went wrong')
        console.log(error)
      })
    //setData(prevData => ({ ...prevData, rows: rowDataJson }));
  }, [dataTableData]);

  return (
    data.rows.length > 0 ? (
      <DataTable table={data} canSearch />
    ) : (
      <MDBox p={2}>
        <NoDataFound
          title="No Data Found"
        />
      </MDBox>
    )
  );
}

export default Accounts;
