
const initialValues = {
  name: "",
  group_category: "",
  prefix: "",
  unit: "",
  labour_upon: "",
  stock_method: "",
  individual_price: "",
  gold_fine: "",
  gold_fine_method: "",
  silver_fine: "",
  silver_fine_method: "",
  amount: "",
  amount_method: ""
};

export default initialValues;