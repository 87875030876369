

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "layouts/users/user-list/components/IdCell";
import DefaultCell from "layouts/users/user-list/components/DefaultCell";
import StatusCell from "layouts/users/user-list/components/StatusCell";
import CustomerCell from "layouts/users/user-list/components/CustomerCell";

import MDButton from "components/MDButton";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import { NavLink } from "react-router-dom";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";

const dataTableData = {
  columns: [
    { Header: "user_id", accessor: "user_id", Cell: ({ value }) => <IdCell id={value} /> },
    {
      Header: "user_first_name",
      accessor: "user_first_name",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "user_last_name",
      accessor: "user_last_name",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "user_email",
      accessor: "user_email",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "user_type",
      accessor: "user_type",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "action",
      accessor: "action",
      Cell: ({ row })  => <>
      <MDButton size="small" variant="gradient" color="dark" component={NavLink} to={`/users/edit-user/${row.original.user_email}`}>Edit</MDButton>
      <MDButton size="small" variant="gradient" color="error" sx={{marginLeft:'10px'}} component={NavLink} to={`/users/edit-roles/${row.original.user_email}`}>Roles</MDButton>
      <Tooltip title="Delete"><Icon color="dark" fontSize="small" sx={{marginLeft:'10px', position:'relative', top:'5px'}}>delete</Icon></Tooltip>
      </>
      
            ,
    },
  ],

  rows: [
    
  ],
};

export default dataTableData;
