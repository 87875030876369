import { useEffect, useState } from "react";

import { Autocomplete } from '@mui/material'
import MDInput from 'components/MDInput'
import React from 'react'

import ItemsData from '../../../../demo_data/itemsData.json'

import { transactionsTypeOption, stampTypeOption, unitOption, labourOption } from "demo_data/const";
import MDButton from "components/MDButton";
import SaleItemRowSelectBox from "../../components/SaleItemRowSelectBox";
import SaleItemRowInput from "../../components/SaleItemRowInput";
import AddItemPopup from "./AddItemPopup";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Padding } from "@mui/icons-material";
import { ConditionalCompare, GetCalculationOf_ItemFine, GetCalculationOf_ItemTotal, GetCalculationOf_Netwt, TotalCalculationOf_Fine, TotalCalculationOf_Grwt, TotalCalculationOf_ItemTotal, TotalCalculationOf_Lbr, TotalCalculationOf_Less, TotalCalculationOf_Netwt, TotalCalculationOf_Pieces, TotalCalculationOf_Wstg } from "../../components/functions";
import { SetFormattedIndianNumber } from "components/globalFunctions";
import { DeletButtonForTable, TableInnerErrorStyle, TableInnerStyle } from "../../components/style";
import { SetNumbersDecimals } from "components/globalFunctions";






const SaleItemRow = ({ saleItems, setSaleItems, deleteRow }) => {
  // Add Item Popup State
  const [addItemPopupState, setAddItemPopupState] = useState(false);

  const allItemsData = ItemsData


  // Temporary Fetch & Save Data From Json
  const [itemsDataOptions, setItemsDataOptions] = useState([])
  useEffect(() => {
    setItemsDataOptions(allItemsData.map((item) => ({ id: item.id, name: item.name })))
  }, [])


  // Adding `Add Item` In `Item` Option
  const itemsDataOptionsWithAddItem = [...itemsDataOptions, { id: 0, name: "Add Item" }];


 


  // param `key name`, val `new value` , index item's row index`, decimalCounting ` if you want to add value in 0.000`
  const updateRow = (param, val, index, decimalCounting) => {
    if (val !== '') {
      setSaleItems((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [param]: decimalCounting || decimalCounting === 0 ? parseFloat(val) : (
            param === 'type' ? val.status : Array.isArray(val) ? val.name : val
          )
          // [param]: decimalCounting || decimalCounting === 0 ? parseFloat(val).toFixed(decimalCounting) : (
          //   // Array.isArray(val) ? (val.status ? val.status : val.name) : val
          //   param === 'type' ? val.status : Array.isArray(val) ? val.name : val
          // )
        };
        return newData;
      });
      checkToAutoSelectAndCalculationMethod(param, val, index, decimalCounting)
    }
  }

  const checkToAutoSelectAndCalculationMethod = (param, val, index, decimalCounting) => {
    switch (param) {
      case "item":
        if (val?.name) {
          const unitVal = allItemsData.find((item) => item.id === val.id)?.unit;
          updateRow("unit", unitVal, index);
        }
        break;

      case "type":
        // Empty for now
        break;

      case "stamp":
        // Empty for now
        break;

      case "unit":
        // Empty for now
        break;

      case "pc":
        // Empty for now
        break;

      case "grwt":
        // Empty for now
        break;

      case "less":
        // Empty for now
        break;

      case "netwt":
        // Empty for now
        break;

      case "tunch":
        // Empty for now
        break;

      case "wstg":
        // Empty for now
        break;

      case "rate":
        // Empty for now
        break;

      case "lbr":
        // Empty for now
        break;

      case "on":
        // Empty for now
        break;

      case "fine":
        // Empty for now
        break;

      case "total":
        // Empty for now
        break;

      default:
        break;
    }
  };


  // Managing Add Item Popup
  const handleToManageAddItemPopup = () => {
    setAddItemPopupState(true)
  }











  return (
    <>
      {saleItems.map((val, i) => (
        <tr key={val.id}>
          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>

            <SaleItemRowSelectBox
              optionName="item"
              data={val}
              updateDataFunction={updateRow}
              options={itemsDataOptionsWithAddItem}
              indexCount={i}
              itsWidth={150}
              handleToManageAddItemPopup={handleToManageAddItemPopup}
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowSelectBox
              optionName="type"
              data={val}
              updateDataFunction={updateRow}
              options={transactionsTypeOption}
              indexCount={i}
              itsWidth={100}
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowSelectBox
              optionName="stamp"
              data={val}
              updateDataFunction={updateRow}
              options={stampTypeOption}
              indexCount={i}
              itsWidth={90}
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowSelectBox
              optionName="unit"
              data={val}
              updateDataFunction={updateRow}
              options={unitOption}
              indexCount={i}
              itsWidth={100}
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowInput
              optionName="pc"
              data={val}
              updateDataFunction={updateRow}
              indexCount={i}
              decimalCounting={0}
              maxLimit={3}
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowInput
              optionName="grwt"
              data={val}
              updateDataFunction={updateRow}
              indexCount={i}
              decimalCounting={3}
              maxLimit={5}
              align="right"
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowInput
              optionName="less"
              data={val}
              updateDataFunction={updateRow}
              indexCount={i}
              decimalCounting={3}
              maxLimit={4}
              align="right"
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            {Number(val.grwt) >= Number(val.less) ? (
              <TableInnerStyle align="right">{SetNumbersDecimals(GetCalculationOf_Netwt(val),3)}</TableInnerStyle>
            ) : (
              <TableInnerErrorStyle align="right">Invalid less weight</TableInnerErrorStyle>
            )}
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowInput
              optionName="tunch"
              data={val}
              updateDataFunction={updateRow}
              indexCount={i}
              decimalCounting={2}
              maxLimit={3}
              align="right"
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowInput
              optionName="wstg"
              data={val}
              updateDataFunction={updateRow}
              indexCount={i}
              decimalCounting={2}
              maxLimit={3}
              align="right"
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowInput
              optionName="rate"
              data={val}
              updateDataFunction={updateRow}
              indexCount={i}
              decimalCounting={2}
              maxLimit={6}
              align="right"
            />

          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowInput
              optionName="lbr"
              data={val}
              updateDataFunction={updateRow}
              indexCount={i}
              decimalCounting={2}
              maxLimit={6}
              align="right"
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <SaleItemRowSelectBox
              optionName="on"
              data={val}
              updateDataFunction={updateRow}
              options={labourOption}
              indexCount={i}
              itsWidth={80}
            />
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <TableInnerStyle align="right">
              {SetFormattedIndianNumber(GetCalculationOf_ItemFine(val), 3)}
            </TableInnerStyle>
          </td>

          <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
            <TableInnerStyle align="right">
              {SetFormattedIndianNumber(GetCalculationOf_ItemTotal(val), 2)}
            </TableInnerStyle>
          </td>

          <td style={{ borderBottom: '1px solid #d8d8d8' }}>
            <DeletButtonForTable onClick={() => { deleteRow(i) }} >
              <DeleteOutlineIcon
                sx={{
                  width: '25px',
                  height: '25px',
                  fill: '#c80500'
                }}
              />
            </DeletButtonForTable>
          </td>
        </tr >
      ))}

      <AddItemPopup
        open={addItemPopupState}
        close={setAddItemPopupState}
      />



















      <tr>
        <td colSpan={4} style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
          <TableInnerStyle>
            {TotalCalculationOf_Pieces(saleItems)}
          </TableInnerStyle>
        </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
          <TableInnerStyle align="right">
            {SetNumbersDecimals(TotalCalculationOf_Grwt(saleItems),3)}
          </TableInnerStyle>
        </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
          <TableInnerStyle align="right">
            {SetNumbersDecimals(TotalCalculationOf_Less(saleItems),3)}
          </TableInnerStyle>
        </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
          <TableInnerStyle align="right">
            {SetNumbersDecimals(TotalCalculationOf_Netwt(saleItems),3)}
          </TableInnerStyle>
        </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
        </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
          <TableInnerStyle align="right">
            {SetNumbersDecimals(TotalCalculationOf_Wstg(saleItems),2)}
          </TableInnerStyle>
        </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
          <TableInnerStyle align="right">
            {SetFormattedIndianNumber(TotalCalculationOf_Lbr(saleItems), 3)}
          </TableInnerStyle>
        </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
          <TableInnerStyle align="right">
            {SetFormattedIndianNumber(TotalCalculationOf_Fine(saleItems), 3)}
          </TableInnerStyle>
        </td>
        <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
          <TableInnerStyle align="right">
            {SetFormattedIndianNumber(TotalCalculationOf_ItemTotal(saleItems), 2)}
          </TableInnerStyle>
        </td>
        <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
      </tr>








    </>
  )
}

export default SaleItemRow