import React  from 'react'

const Invoice = ({ contentToPrint, invoicesData }) => {

  
  // To Manage `GST in bill` Options `ID. 1` type `switch`
  const gstOptionManage = (html) => {
    if (invoicesData?.options[0].state) { return html }
    return
  }


  return (
    <>

      <div style={{ width: '100%', maxWidth: '796px', margin: 'auto', border: '1px solid #b9b9b9', borderRadius: '10px' }}>
        <div ref={contentToPrint} style={{ width: '100%', padding: '16px' }}>
          <table cellPadding={0} cellSpacing={0} style={{ width: '100%', fontSize: '14px', fontWeight: 600, border: '2px solid #000', borderRadius: '5px', lineHeight: 1.3, color: '#990909', wordBreak: 'break-word' }}>
            <tbody>















              <tr>
                <td colSpan={9} style={{ padding: '10px', borderBottom: '1px solid #000' }}>
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '28px', fontWeight: '600' }}>LONDON JEWELLERS</div>
                    <div style={{ color: '#078707' }}>290-291, SARAFAN BAZAR</div>
                    <div style={{ color: '#078707' }}>LUDHIANA</div>
                  </div>
                </td>
              </tr>




              <tr>
                <td colSpan={3} style={{ padding: '10px', borderBottom: '1px solid #000' }}>
                  <div>GSTIN : 03BIDPL5564L1Z3</div>
                  <div>PAN : BIDPL5564L</div>
                  <div>664388205106</div>
                </td>
                <td colSpan={3} align='center' style={{ padding: '10px', borderBottom: '1px solid #000' }}>
                  <div style={{ display: 'inline-block', width: 'auto', minWidth: '80%', background: '#b8ffb4', border: '1px solid #88c984', padding: '10px 5px', borderRadius: '3px', color: '#000' }}>
                    <div style={{ fontSize: '120%' }}>GST TAX INVOICE</div>
                    <div style={{ fontSize: '80%' }}>CUSTOMER COPY</div>
                  </div>
                </td>
                <td colSpan={3} align='right' style={{ padding: '10px', borderBottom: '1px solid #000' }}>
                  <div>7696330802</div>
                  <div>98147-35283</div>
                  <div>7973174802</div>
                </td>
              </tr>




              <tr>
                <td colSpan={5} valign='top' style={{ width: '55.55%', padding: '10px 10px 5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                  <div style={{ height: '115px' }}>
                    <div style={{ textAlign: 'center', color: '#078707', paddingBottom: '10px' }}>Buyer's Name & Address:-</div>
                    <div>GABA JEWELLERS</div>
                    <div>KOTHI ROAD, NAWANSHAHR NAWANSHAHR, Nawan Shahar - Ward No.1 Punjab</div>
                  </div>

                  <table cellPadding={0} cellSpacing={0} style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ color: '#078707' }}>Phone :</td>
                        <td colSpan={3}></td>
                      </tr>
                      <tr>
                        <td style={{ color: '#078707' }}>Email :</td>
                        <td colSpan={3}></td>
                      </tr>
                      <tr>
                        <td style={{ color: '#078707' }}>State :</td>
                        <td colSpan={3}>03-PB</td>
                      </tr>
                      <tr>
                        <td style={{ color: '#078707' }}>GSTIN :</td>
                        <td>03AAJFG4476H1ZD</td>

                        <td style={{ color: '#078707' }}>PAN :</td>
                        <td>AAJFG4476H</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td colSpan={4} valign='top' style={{ width: '44.44%', borderBottom: '1px solid #000' }}>
                  <table cellPadding={0} cellSpacing={0} style={{ width: '100%', }}>
                    <tbody>
                      <tr>
                        <td style={{ height: '30px', color: '#078707', padding: '0 5px', whiteSpace: 'pre', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Invoice No.</td>
                        <td style={{ padding: '0 5px', borderBottom: '1px solid #000' }}>24-25-R29</td>
                      </tr>
                      <tr>
                        <td style={{ height: '30px', color: '#078707', padding: '0 5px', whiteSpace: 'pre', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Invoice Date</td>
                        <td style={{ padding: '0 5px', borderBottom: '1px solid #000' }}>10-09-2024</td>
                      </tr>
                      <tr>
                        <td style={{ height: '30px', color: '#078707', padding: '0 5px', whiteSpace: 'pre', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Term</td>
                        <td style={{ padding: '0 5px', borderBottom: '1px solid #000' }}>Credit</td>
                      </tr>
                      <tr>
                        <td style={{ height: '30px', color: '#078707', padding: '0 5px', whiteSpace: 'pre', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>E-INV Ach No</td>
                        <td style={{ padding: '0 5px', borderBottom: '1px solid #000' }}>132419529007524</td>
                      </tr>
                      <tr>
                        <td style={{ height: '30px', color: '#078707', padding: '0 5px', whiteSpace: 'pre', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>E-INV IRN No</td>
                        <td style={{ padding: '0 5px', wordBreak: 'break-all', borderBottom: '1px solid #000', fontSize: '80%', lineHeight: 1.1 }}>7f36ca64ec7ef7623794caf69bfda5b0c4d148f5f541aedd0df44212348d8ae</td>
                      </tr>
                      <tr>
                        <td style={{ height: '30px', color: '#078707', padding: '0 5px', whiteSpace: 'pre', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Freight</td>
                        <td style={{ padding: '0 5px', borderBottom: '1px solid #000' }}></td>
                      </tr>
                      <tr>
                        <td style={{ height: '30px', color: '#078707', padding: '0 5px', whiteSpace: 'pre', borderRight: '1px solid #000' }}>Vehicle No.</td>
                        <td style={{ padding: '0 5px', whiteSpace: 'pre' }}></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>



              <tr>
                <td style={{ padding: '5px 10px', textAlign: 'center', background: '#b8ffb4', color: '#000', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>S/N</td>
                <td colSpan={2} style={{ padding: '5px 10px', textAlign: 'center', background: '#b8ffb4', color: '#000', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Description of Goods</td>
                <td style={{ padding: '5px 10px', textAlign: 'center', background: '#b8ffb4', color: '#000', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>HSN/SAC</td>
                {gstOptionManage(
                  <td style={{ padding: '5px 10px', textAlign: 'center', background: '#b8ffb4', color: '#000', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>GST%</td>
                )}
                <td style={{ padding: '5px 10px', textAlign: 'center', background: '#b8ffb4', color: '#000', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Qty</td>
                <td style={{ padding: '5px 10px', textAlign: 'center', background: '#b8ffb4', color: '#000', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Unit</td>
                <td style={{ padding: '5px 10px', textAlign: 'center', background: '#b8ffb4', color: '#000', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>Rate</td>
                <td style={{ padding: '5px 10px', textAlign: 'center', background: '#b8ffb4', color: '#000', borderBottom: '1px solid #000' }}>Amount</td>
              </tr>
              <tr>
                <td style={{ padding: '5px 10px', fontWeight: 400, textAlign: 'center', borderRight: '1px solid #000' }}>1</td>
                <td colSpan={2} style={{ padding: '5px 10px', textAlign: 'center', borderRight: '1px solid #000' }}>GOLD BAR</td>
                <td style={{ padding: '5px 10px', fontWeight: 400, textAlign: 'center', borderRight: '1px solid #000', whiteSpace: 'pre' }}>71081200</td>
                {gstOptionManage(
                  <td style={{ padding: '5px 10px', fontWeight: 400, textAlign: 'center', borderRight: '1px solid #000', whiteSpace: 'pre' }}>3.00</td>
                )}
                <td style={{ padding: '5px 10px', fontWeight: 400, textAlign: 'right', borderRight: '1px solid #000', whiteSpace: 'pre' }}>27.680</td>
                <td style={{ padding: '5px 10px', fontWeight: 400, textAlign: 'right', borderRight: '1px solid #000', whiteSpace: 'pre' }}>GMS.</td>
                <td style={{ padding: '5px 10px', fontWeight: 400, textAlign: 'right', borderRight: '1px solid #000', whiteSpace: 'pre' }}>7014.98</td>
                <td style={{ padding: '5px 10px', fontWeight: 400, textAlign: 'right', whiteSpace: 'pre' }}>194174.65</td>
              </tr>



              {/* <tr>
                  <td style={{ padding: '5px 10px', textAlign: 'center', borderRight: '1px solid #000' }}>&nbsp;</td>
                  <td colSpan={2} style={{ padding: '5px 10px', textAlign: 'center', borderRight: '1px solid #000' }}></td>
                  <td style={{ padding: '5px 10px', textAlign: 'center', borderRight: '1px solid #000', whiteSpace: 'pre' }}></td>
                  <td style={{ padding: '5px 10px', textAlign: 'center', borderRight: '1px solid #000', whiteSpace: 'pre' }}></td>
                  <td style={{ padding: '5px 10px', textAlign: 'right', borderRight: '1px solid #000', whiteSpace: 'pre' }}></td>
                  <td style={{ padding: '5px 10px', textAlign: 'right', borderRight: '1px solid #000', whiteSpace: 'pre' }}></td>
                  <td style={{ padding: '5px 10px', textAlign: 'right', borderRight: '1px solid #000', whiteSpace: 'pre' }}></td>
                  <td style={{ padding: '5px 10px', textAlign: 'right', whiteSpace: 'pre' }}></td>
                </tr> */}
              {/* <tr><td>&nbsp;</td><td colSpan={2}></td><td></td><td></td><td></td><td></td><td></td><td></td></tr> */}


              {[...Array(8)].map((_, index2) => (
                <tr key={index2}> {/* Temporary Key */}
                  <td style={{ padding: '5px 10px', textAlign: 'center', borderRight: '1px solid #000' }}>&nbsp;</td>
                  <td colSpan={2} style={{ padding: '5px 10px', textAlign: 'center', borderRight: '1px solid #000' }}></td>
                  <td style={{ padding: '5px 10px', textAlign: 'center', borderRight: '1px solid #000', whiteSpace: 'pre' }}></td>
                  {gstOptionManage(
                    <td style={{ padding: '5px 10px', textAlign: 'center', borderRight: '1px solid #000', whiteSpace: 'pre' }}></td>
                  )}
                  <td style={{ padding: '5px 10px', textAlign: 'right', borderRight: '1px solid #000', whiteSpace: 'pre' }}></td>
                  <td style={{ padding: '5px 10px', textAlign: 'right', borderRight: '1px solid #000', whiteSpace: 'pre' }}></td>
                  <td style={{ padding: '5px 10px', textAlign: 'right', borderRight: '1px solid #000', whiteSpace: 'pre' }}></td>
                  <td style={{ padding: '5px 10px', textAlign: 'right', whiteSpace: 'pre' }}></td>
                </tr>
              ))}







              <tr>
                <td valign='top' colSpan={5}>
                  <table cellPadding={0} cellSpacing={0} style={{ width: '100%', borderTop: '1px solid #000', borderRight: '1px solid #000' }}>
                    <tbody>
                      <tr>
                        <td height={25} style={{ fontSize: '80%', color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>GST%</td>
                        <td style={{ fontSize: '80%', textAlign: 'center', color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>A.Value</td>
                        <td style={{ fontSize: '80%', textAlign: 'center', color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>SGST</td>
                        <td style={{ fontSize: '80%', textAlign: 'center', color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>CGST</td>
                        <td style={{ fontSize: '80%', textAlign: 'center', color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>IGST</td>
                        <td style={{ fontSize: '80%', textAlign: 'right', color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>Total GST</td>
                      </tr>
                      <tr>
                        <td height={25} style={{ fontSize: '80%', fontWeight: 500, padding: '0 5px', borderBottom: '1px solid #000' }}>3.00</td>
                        <td style={{ fontSize: '80%', fontWeight: 500, textAlign: 'center', padding: '0 5px', borderBottom: '1px solid #000' }}>194174.65</td>
                        <td style={{ fontSize: '80%', fontWeight: 500, textAlign: 'center', padding: '0 5px', borderBottom: '1px solid #000' }}>2912.62</td>
                        <td style={{ fontSize: '80%', fontWeight: 500, textAlign: 'center', padding: '0 5px', borderBottom: '1px solid #000' }}>2912.62</td>
                        <td style={{ fontSize: '80%', fontWeight: 500, textAlign: 'center', padding: '0 5px', borderBottom: '1px solid #000' }}>0.00</td>
                        <td style={{ fontSize: '80%', fontWeight: 500, textAlign: 'right', padding: '0 5px', borderBottom: '1px solid #000' }}>5825.24</td>
                      </tr>
                      <tr>
                        <td height={25} style={{ fontSize: '80%', padding: '0 5px', color: '#078707', borderBottom: '1px solid #000' }}>Total</td>
                        <td style={{ fontSize: '80%', textAlign: 'center', padding: '0 5px', borderBottom: '1px solid #000' }}>194174.65</td>
                        <td style={{ fontSize: '80%', textAlign: 'center', padding: '0 5px', borderBottom: '1px solid #000' }}>2912.62</td>
                        <td style={{ fontSize: '80%', textAlign: 'center', padding: '0 5px', borderBottom: '1px solid #000' }}>2912.62</td>
                        <td style={{ fontSize: '80%', textAlign: 'center', padding: '0 5px', borderBottom: '1px solid #000' }}>0.00</td>
                        <td style={{ fontSize: '80%', textAlign: 'right', padding: '0 5px', borderBottom: '1px solid #000' }}>5825.24</td>
                      </tr>

                      <tr><td height={25} colSpan={6}>&nbsp;</td></tr>
                      <tr><td height={25} colSpan={6} style={{ borderBottom: '1px solid #000' }} >&nbsp;</td></tr>

                      <tr><td height={25} colSpan={6} style={{ padding: '0 5px' }}>OUR BANK : RBL BANK</td></tr>
                      <tr><td height={25} colSpan={6} style={{ padding: '0 5px' }}>A/C NO.409001653435</td></tr>
                      <tr><td height={25} colSpan={6} style={{ padding: '0 5px' }}>IFSC CODE:RATN0000203</td></tr>
                      <tr><td height={25} colSpan={6} style={{ padding: '0 5px', borderBottom: '1px solid #000' }}>BRANCH </td></tr>
                    </tbody>
                  </table>
                </td>
                <td valign='top' colSpan={4}>
                  {/* <table border={1} style={{ width: '100%' }}> */}
                  <table cellPadding={0} cellSpacing={0} style={{ width: '100%', borderTop: '1px solid #000' }}>
                    <tbody>
                      <tr>
                        <td height={25} style={{ color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>Sub Total</td>
                        <td style={{ textAlign: 'right', padding: '0 5px', borderBottom: '1px solid #000' }}>194,174.65</td>
                      </tr>
                      <tr><td height={25} colSpan={2} style={{ borderBottom: '1px solid #000' }}>&nbsp;</td></tr>
                      <tr><td height={25} colSpan={2} style={{ borderBottom: '1px solid #000' }}>&nbsp;</td></tr>
                      <tr>
                        <td height={25} style={{ color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>CGST @ 1.50 %</td>
                        <td style={{ textAlign: 'right', padding: '0 5px', borderBottom: '1px solid #000', fontWeight: 400 }}>2,912.62</td>
                      </tr>
                      <tr>
                        <td height={25} style={{ color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>SGST @ 1.50 %</td>
                        <td style={{ textAlign: 'right', padding: '0 5px', borderBottom: '1px solid #000', fontWeight: 400 }}>2,912.62</td>
                      </tr>
                      <tr><td height={25} colSpan={2} style={{ borderBottom: '1px solid #000' }}>&nbsp;</td></tr>
                      <tr><td height={25} colSpan={2} style={{ borderBottom: '1px solid #000' }}>&nbsp;</td></tr>
                      <tr>
                        <td height={25} style={{ fontSize: '90%', color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>Round Off</td>
                        <td style={{ fontSize: '90%', textAlign: 'right', padding: '0 5px', borderBottom: '1px solid #000', fontWeight: 400 }}>0.11</td>
                      </tr>
                      <tr>
                        <td height={25} style={{ color: '#078707', padding: '0 5px', borderBottom: '1px solid #000' }}>Grand Total</td>
                        <td style={{ textAlign: 'right', padding: '0 5px', borderBottom: '1px solid #000' }}>200,000.00</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>



              <tr>
                <td colSpan={9} height={25} style={{ padding: '0px 10px', fontWeight: 400, background: '#b8ffb4', color: '#000', borderBottom: '1px solid #000' }}>RUPEES TWO LAKHS ONLY</td>
              </tr>



              <tr>
                <td valign='top' colSpan={5} style={{ padding: '5px', color: '#078707', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>
                  <div style={{ color: '#990909' }}>TERMS & CONDITIONS</div>
                  <div style={{ fontSize: '90%' }} dangerouslySetInnerHTML={{ __html: invoicesData?.options[2].state }}></div>
                </td>
                <td valign='bottom' colSpan={1} style={{ padding: '5px', borderRight: '1px solid #000', borderBottom: '1px solid #000' }}>
                  <div style={{ textAlign: 'center', color: '#078707', }}>Receiver's Signature</div>
                </td>
                <td colSpan={3} style={{ padding: '5px', borderBottom: '1px solid #000' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', height: '100px', justifyContent: 'space-between', alignItems: 'flex-end', }}>
                    <div>For LONDON JEWELLERS</div>
                    <div style={{ color: '#078707', fontWeight: 400 }}>Authorised Signatory</div>
                  </div>
                </td>
              </tr>



              <tr>
                <td colSpan={9} height={25} style={{ textAlign: 'center', padding: '0 5px', fontSize: '80%' }}>ACCOUNT DEP : RAVI- 98147- 35283 JIMMY - 96463 -72073 PARDEEP - 98887-35283 SANJU - 95697- 94263</td>
              </tr>









            </tbody>
          </table>


          <div style={{ paddingTop: '5px', textAlign: 'center', fontSize: '12px', fontWeight: 500, lineHeight: 1.3, color: '#000' }}>User Name ABC1 (Time : 11:57:48 AM)</div>


        </div>
      </div>

    </>
  )
}

export default Invoice