const form = {
  formId: "companyForm",
  formField: {
    group: { name: "group", label: "Group" },
    name: { name: "name", label: "Name" },
    contact_no: { name: "contact_no", label: "Contact No" },
    alternate_contact_no: { name: "alternate_contact_no", label: "Alternate Contact No" },
    date_of_birth: { name: "date_of_birth", label: "Date of birth" },
    address: { name: "address", label: "Address" },



    gold_fine: { name: "gold_fine", label: "Gold fine" },
    gold_fine_method: { name: "gold_fine_method", label: "Method" },

    silver_fine: { name: "silver_fine", label: "Silver fine" },
    silver_fine_method: { name: "silver_fine_method", label: "Method" },

    amount: { name: "amount", label: "Amount" },
    amount_method: { name: "amount_method", label: "Method" },

  },
};

export default form;
