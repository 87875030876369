import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import SaleItemRowSelectBox from './SaleItemRowSelectBox';
import SaleItemRowInput from './SaleItemRowInput';
import { transactionsTypeOption2 } from 'demo_data/const';
import { paymentMethodOption } from 'demo_data/const';
import { InputBoxReplicaStyle } from './style';
import { SetFormattedIndianNumber } from 'components/globalFunctions';
import { AutoFillValues_Amount_Fine_Rate, colorAccordingType, ConditionalCompare, GetCalculationOf_Row2_Fine } from './functions';
import MDBox from 'components/MDBox';
import { Card, Grid, Switch } from '@mui/material';
import MDButton from 'components/MDButton';
import { v4 as uuidv4 } from 'uuid';
import MDTypography from 'components/MDTypography';
import EastIcon from '@mui/icons-material/East';
import { transactionsTypeOption3 } from 'demo_data/const';
import { bankAccountsOptions } from 'demo_data/const';

const PanelPopup = ({ panelPopupState, setPanelPopupState, panelPopupData, setPanels, saleItems }) => {


  // console.log('panelPopupState', panelPopupState);
  // console.log('panelPopupData', panelPopupData);




  const [panelsData, setPanelsData] = useState({
    id: uuidv4(),
    type: 'inflow',
    method: '',
    miaGoldBhav: true,
    bank: null,
    useAsGoldBhav: false,
    amount: '0.000',
    grwt: '0.000',
    tunch: '100.00',
    fine: '0.000',
    rate: '0.00',
  });



  useEffect(() => {
    if (panelPopupData) {
      setPanelsData((prevData) => ({
        ...prevData,
        type: panelPopupData.type ?? 'inflow',
        method: panelPopupData.method ?? '',
        miaGoldBhav: panelPopupData.miaGoldBhav !== undefined ? panelPopupData.miaGoldBhav : true,
        bank: panelPopupData.bank ?? null,
        useAsGoldBhav: panelPopupData.useAsGoldBhav !== undefined ? panelPopupData.useAsGoldBhav : false,
        grwt: panelPopupData.grwt || '0.000',
        id: panelPopupData.id || uuidv4(),
        amount: panelPopupData.amount || '0.00',
        tunch: panelPopupData.tunch || '100.00',
        fine: panelPopupData.fine || '0.000',
        rate: panelPopupData.rate || '0.00',
      }));
    }
  }, [panelPopupData]);














  // param `key name`, val `new value` , index item's row index`, decimalCounting ` if you want to add value in 0.000`
  // const updateRowFunction = (param, val, index, decimalCounting) => {
  //   setPanelsData((prevData) => ({
  //     ...prevData,
  //     [param]: decimalCounting || decimalCounting === 0
  //       ? parseFloat(val).toFixed(decimalCounting)
  //       : (Array.isArray(val) ? val.name : val)
  //   }));
  // };

  const updateRowFunction = (param, val, index, decimalCounting) => {
    console.log('1111111111', param, val, index, decimalCounting);

    setPanelsData((prevData) => ({
      ...prevData,
      [param]: decimalCounting || decimalCounting === 0 ? (
        // (typeof val === 'boolean') ? val : parseFloat(val).toFixed(decimalCounting)
        (typeof val === 'boolean') ? val : parseFloat(val)
      ) : (
        param === 'type' ? val.status : Array.isArray(val) ? val[index]?.name : val
      )
    }));
  };

  const updateRow2 = (param, val, index, decimalCounting) => {
    if (val !== '') {
      updateRowFunction(param, val, index, decimalCounting);
      checkToAutoSelectAndCalculationMethod(param, val, index, decimalCounting);
    }
  };

  const updateRow2_OneTime = (param, val, index, decimalCounting) => {
    if (val !== '') {
      // console.log('updateRow2_OneTime', param, val, index, decimalCounting);
      updateRowFunction(param, val, index, decimalCounting);
    }
  };

  const checkToAutoSelectAndCalculationMethod = (param, val, index, decimalCounting) => {



    switch (param) {
      case "amount":
        AutoFillValues_Amount_Fine_Rate(param, val, index, decimalCounting, panelsData, saleItems, updateRow2_OneTime)
        break;

      case "grwt":
        AutoFillValues_Amount_Fine_Rate(param, val, index, decimalCounting, panelsData, saleItems, updateRow2_OneTime)
        break;

      case "tunch":
        AutoFillValues_Amount_Fine_Rate(param, val, index, decimalCounting, panelsData, saleItems, updateRow2_OneTime)
        break;

      case "rate":
        AutoFillValues_Amount_Fine_Rate(param, val, index, decimalCounting, panelsData, saleItems, updateRow2_OneTime)
        break;

      default:
        break;
    }
  };








  const handleToSavePanelRow = () => {

    let shouldUseAsGoldBhav = false;

    if (
      ConditionalCompare(panelsData.method, '!==', 'metal') &&
      ConditionalCompare(panelsData.method, '!==', 'gold bhav') &&
      panelsData.amount > 0.01 &&
      panelsData.grwt > 0.001 &&
      panelsData.rate > 0.01
    ) {
      shouldUseAsGoldBhav = true
    }






    const updatedPanelsData = {
      ...panelsData,
      useAsGoldBhav: !!shouldUseAsGoldBhav // convert to boolean
    };

    if (panelPopupState.type === 'add') {
      // console.log('11111111111111 add',updatedPanelsData);
      setPanels((prevData) => [
        ...prevData,
        updatedPanelsData
      ]);
    }

    if (panelPopupState.type === 'edit') {
      // console.log('11111111111111 edit',updatedPanelsData);
      setPanels((prevData) =>
        prevData.map((panel) =>
          panel.id === updatedPanelsData.id
            ? { ...panel, ...updatedPanelsData }
            : panel
        )
      );
    }

    setPanelsData({
      id: uuidv4(),
      type: 'inflow',
      method: null,
      miaGoldBhav: true,
      bank: null,
      useAsGoldBhav: false,
      amount: '0.000',
      grwt: '0.000',
      tunch: '100.00',
      fine: '0.000',
      rate: '0.00',
    });

    setPanelPopupState((prevState) => ({ ...prevState, visibility: false }));

  }



  const handleToUpdate_miaGoldBhav = (toggle) => {
    setPanelsData((prevData) => ({
      ...prevData,
      miaGoldBhav: toggle
    }))



    // setPanelsData((prevData) => ({
    //   ...prevData,
    //   ...(toggle ? { amount: '0.000' } : { grwt: '0.000' })
    // }));





  }














  const isCashChequeCard = (
    ConditionalCompare(panelsData.method, '===', 'cash') ||
    ConditionalCompare(panelsData.method, '===', 'cheque') ||
    ConditionalCompare(panelsData.method, '===', 'card')
  );

  const isMetal = (
    ConditionalCompare(panelsData.method, '===', 'metal')
  );

  const isGoldBhav = (
    ConditionalCompare(panelsData.method, '===', 'gold bhav')
  );

  let validationForSubmit = (
    (
      isCashChequeCard && (
        panelsData.amount > 0.01 && panelsData.grwt > 0.001 && panelsData.rate > 0.01
      )
    ) || (
      isCashChequeCard && (
        panelsData.amount > 0.01 && panelsData.grwt < 0.001 && panelsData.rate < 0.01
      )
    ) || (
      isMetal && (
        panelsData.grwt > 0.01 && panelsData.tunch > 0.01
      )
    ) || (
      isGoldBhav && (
        panelsData.amount > 0.01 && panelsData.grwt > 0.001 && panelsData.rate > 0.01
      )
    )
  );













  return (
    <Drawer
      // anchor="right"
      anchor="left"
      open={panelPopupState.visibility}
      sx={{
        '& .MuiPaper-root': {
          width: 300,
          overflowX: 'hidden'
        }
      }}
      onClose={() => {
        setPanelPopupState((prevState) => ({ ...prevState, visibility: false }));
      }}

    >
      <Box
        role="presentation"
      // onClick={() => { setPanelPopupState(item=>(...item,visibility = false)) }}
      >


        <Card autoComplete="off" sx={{ height: "100%", boxShadow: 'none' }}>


          <MDBox p={3} pb={0}>
            <MDTypography variant="h5">
              {panelPopupState.type === 'add' ? (
                'Add a New Item'
              ) : (
                'Edit This Item'
              )}
            </MDTypography>
          </MDBox>


          <MDBox p={3}>
            <Grid container spacing={3}>


              <Grid item xs={12} sm={12}>
                <SaleItemRowSelectBox
                  optionName="type"
                  data={panelsData}
                  updateDataFunction={updateRow2}
                  options={
                    ConditionalCompare(panelsData.method, '===', 'gold bhav') ? transactionsTypeOption3 : transactionsTypeOption2
                  }
                  label='Type'
                  inputStyle="outlined"
                // autoFocus={true}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <SaleItemRowSelectBox
                  optionName="method"
                  data={panelsData}
                  updateDataFunction={updateRow2}
                  options={paymentMethodOption}
                  label='Method'
                  inputStyle="outlined"
                />
              </Grid>





              {/* {ConditionalCompare(panelsData.method, '!==', 'metal') ? ( */}
              {ConditionalCompare(panelsData.method, '===', 'gold bhav') ? (
                <Grid item xs={12} sm={12}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <MDBox display="flex" alignItems="center">
                      <MDBox lineHeight={0}>
                        <MDTypography display="flex" alignItems="center" gap={1} variant="h6" fontWeight="medium" sx={{ fontSize: '14px' }}>
                          {panelsData.miaGoldBhav ? <>
                            <p style={{ color: colorAccordingType(false) }}>Metal</p>
                            <EastIcon sx={{ width: 20, height: 20 }} />
                            <p style={{ color: colorAccordingType(true) }}>Amount</p>
                          </> : <>
                            <p style={{ color: colorAccordingType(false) }}>Amount</p>
                            <EastIcon sx={{ width: 20, height: 20 }} />
                            <p style={{ color: colorAccordingType(true) }}>Metal</p>
                          </>}
                        </MDTypography>
                      </MDBox>
                    </MDBox>

                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      width={{ xs: "100%", sm: "auto" }}
                      mt={{ xs: 1, sm: 0 }}
                    >
                      <MDBox>
                        <Switch
                          checked={panelsData.miaGoldBhav}
                          onChange={(e) => { handleToUpdate_miaGoldBhav(!panelsData.miaGoldBhav) }}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Grid>
              ) : ('')}





              {ConditionalCompare(panelsData?.method, '===', 'cheque') ||
                ConditionalCompare(panelsData?.method, '===', 'card') ? (
                <Grid item xs={12} sm={12}>
                  <SaleItemRowSelectBox
                    optionName="bank"
                    data={panelsData}
                    updateDataFunction={updateRow2}
                    options={bankAccountsOptions}
                    label='Bank'
                    inputStyle="outlined"
                  />
                </Grid>
              ) : ''}






















              {ConditionalCompare(panelsData.method, '!==', 'metal') ? (<>
                {!(ConditionalCompare(panelsData.method, '===', 'gold bhav') && panelsData.miaGoldBhav) && (
                  <Grid item xs={12} sm={12}>
                    <SaleItemRowInput
                      optionName="amount"
                      data={panelsData}
                      updateDataFunction={updateRow2}
                      label="Amount"
                      decimalCounting={2}
                      maxLimit={7}
                      inputStyle="outlined"
                      autoFocus={ConditionalCompare(panelsData.method, '===', 'cash') ? true : false}
                    />
                  </Grid>
                )}

                {ConditionalCompare(panelsData.method, '===', 'gold bhav') && panelsData.miaGoldBhav && (
                  <Grid item xs={12} sm={12}>
                    <InputBoxReplicaStyle>
                      <label>Amount</label>
                      <p>{SetFormattedIndianNumber(panelsData.amount, 2)}</p>
                    </InputBoxReplicaStyle>
                  </Grid>
                )}


              </>) : ('')}








































              {ConditionalCompare(panelsData.method, '!==', 'metal') ? (
                <Grid item xs={12} sm={12}>
                  <SaleItemRowInput
                    optionName="rate"
                    data={panelsData}
                    updateDataFunction={updateRow2}
                    label='Rate'
                    decimalCounting={2}
                    maxLimit={6}
                    inputStyle="outlined"
                    autoFocus={ConditionalCompare(panelsData.method, '===', 'gold bhav') ? true : false}
                  />
                </Grid>
              ) : ('')}



              {!(ConditionalCompare(panelsData.method, '===', 'gold bhav') && !panelsData.miaGoldBhav) && (

                (['card', 'cheque', 'cash'].includes(panelsData.method) && panelsData.amount > 0 && panelsData.rate > 0) ? (
                  <Grid item xs={12} sm={6}>
                    <InputBoxReplicaStyle>
                      <label>Gr.Wt</label>
                      <p>{SetFormattedIndianNumber(panelsData.grwt, 3)}</p>
                    </InputBoxReplicaStyle>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}>
                    <SaleItemRowInput
                      optionName="grwt"
                      data={panelsData}
                      updateDataFunction={updateRow2}
                      label='Gr.Wt'
                      decimalCounting={3}
                      maxLimit={5}
                      inputStyle="outlined"
                      autoFocus={ConditionalCompare(panelsData.method, '===', 'metal')}
                      readOnly={ConditionalCompare(panelsData.method, '===', 'gold bhav') && !panelsData.miaGoldBhav}
                    />
                  </Grid>
                )
              )}



              {ConditionalCompare(panelsData.method, '===', 'gold bhav') && !panelsData.miaGoldBhav && (
                <Grid item xs={12} sm={6}>
                  <InputBoxReplicaStyle>
                    <label>Gr.Wt</label>
                    <p>{SetFormattedIndianNumber(panelsData.grwt, 3)}</p>
                  </InputBoxReplicaStyle>
                </Grid>
              )}





              {ConditionalCompare(panelsData.method, '===', 'metal') ? (
                <Grid item xs={12} sm={6}>
                  <SaleItemRowInput
                    optionName="tunch"
                    data={panelsData}
                    updateDataFunction={updateRow2}
                    label='Tunch'
                    decimalCounting={2}
                    maxLimit={3}
                    inputStyle="outlined"
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6}>
                  <InputBoxReplicaStyle>
                    <label>Tunch</label>
                    <p>{SetFormattedIndianNumber(panelsData.tunch, 2)}</p>
                  </InputBoxReplicaStyle>
                </Grid>
              )}






              <Grid item xs={12} sm={12}>
                <InputBoxReplicaStyle>
                  <label>Fine</label>
                  <p>{SetFormattedIndianNumber(GetCalculationOf_Row2_Fine(panelsData), 3)}</p>
                </InputBoxReplicaStyle>
              </Grid>

















            </Grid>

            <MDBox mt={4} gap={2} width="100%" display="flex" justifyContent="center">
              <MDBox mb={2}>
                <MDButton
                  variant="gradient"
                  size="small"
                  color="primary"
                  onClick={() => { handleToSavePanelRow() }}




                  disabled={!validationForSubmit}



                >
                  {panelPopupState.type === 'add' ? (
                    'Create New'
                  ) : (
                    'Save Changes'
                  )}
                </MDButton>
              </MDBox>



              <MDBox mb={2}>
                <MDButton
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setPanelPopupState((prevState) => ({ ...prevState, visibility: false }));
                  }}
                >Cancel</MDButton>
              </MDBox>
            </MDBox>




          </MDBox>


        </Card>



      </Box>
    </Drawer>
  )
}

export default PanelPopup