

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

//  React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import UserInfo from "layouts/users/new-user/components/UserInfo";

// NewUser layout schemas for form and form feilds
import validations from "layouts/users/new-user/schemas/validations";
import form from "layouts/users/new-user/schemas/form";
import initialValues from "layouts/users/new-user/schemas/initialValues";

const axios = require('axios').default

function getSteps() {
  return ["User Info", "Address", "Social", "Profile"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} />;
    default:
      return null;
  }
}

function NewUser() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    await sleep(1000);
    console.log(values)

    var user_id = randomString(13)

    axios
      .post('https://admin.goldshopper.in/api/registeruser', {
        user_id: user_id,
        user_email: values.email,
        user_password: values.password,
        user_first_name: values.firstName,
        user_last_name: values.lastName,
        user_type: values.role,
        user_activated: true,
      })
      .then( function (response) {
       console.log(response.data)
       if(response.data === 'done'){
        alert('User Added')
        actions.setSubmitting(false);
        actions.resetForm();
       }

       //window.location.href = '/dashboards/analytics'
      })
      .catch(function (error) {
        alert('Something went wrong')
        //openErrorSB()
        console.log(error)
      })
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    // if (isLastStep) {
    //   submitForm(values, actions);
    // } else {
    //   setActiveStep(activeStep + 1);
    //   actions.setTouched({});
    //   actions.setSubmitting(false);
    // }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    {/* <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox> */}
                    <MDBox p={3} mt={1}>
                      <MDBox>
                        {/* {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })} */}
                        <UserInfo formData={{
                          values,
                          touched,
                          formField,
                          errors,
                        }} />
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                        <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            Next
                          </MDButton>
                          {/* {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? "send" : "next"}
                          </MDButton> */}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUser;
