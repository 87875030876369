// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AddItemForm from "./components/AddItemForm";



const AddItem = () => {

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={2}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <AddItemForm />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default AddItem;
